import styled from 'styled-components';

import { convertPxToRem, paragraphs } from '@proprioo/salatim';

export const Layout = styled.div`
  max-width: ${convertPxToRem(570)};
  margin: ${convertPxToRem(16)} auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const Subtitle = styled.p`
  ${paragraphs.body1};
  margin: ${convertPxToRem(16)} 0 ${convertPxToRem(18)};
`;
