import styled, { css } from 'styled-components';

export const Layout = styled.div<{ isOpen: boolean }>`
  position: fixed;
  z-index: 100;
  top: 110%;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: white;
  transition: top 0.2s;

  ${({ isOpen }) =>
    isOpen &&
    css`
      top: 0;
    `}
`;
