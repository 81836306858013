import React, { FC, PropsWithChildren } from 'react';

import { Grid, GridWrapper } from './GridLine.styles';

type GridLineProps = {
  nbLines?: number;
};

const GridLine: FC<PropsWithChildren<GridLineProps>> = ({
  nbLines = 4,
  children
}) => (
  <GridWrapper>
    <Grid nbLines={nbLines}>{children}</Grid>
  </GridWrapper>
);

export default GridLine;
