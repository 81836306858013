import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Bell from '@/assets/icons/bell.svg';
import CustomLink from '@/components/common/customLink/CustomLink';
import { UrlProps } from '@/constants/global';
import { Button, ButtonAppearance, H4 } from '@proprioo/salatim';

import { Layout, Subtitle } from './NoResults.styles';

type NoResultsProps = {
  alertLink: UrlProps;
};

const NoResults: FC<NoResultsProps> = ({ alertLink }) => {
  const { t } = useTranslation();

  return (
    <Layout>
      <H4 title={t('noResultsTitle')} />
      <Subtitle>{t('noResultsSubtitle')}</Subtitle>
      <CustomLink link={alertLink} dataTest="link-no-results">
        <Button
          label={t('alertButton')}
          appearance={ButtonAppearance.PRIMARY}
          icon={<Bell />}
        />
      </CustomLink>
    </Layout>
  );
};

export default NoResults;
