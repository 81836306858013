import { FC, Fragment } from 'react';

import { PaginationProps } from '../interfaces';
import PageNumber from '../pageNumber/PageNumber';

const BasicPagination: FC<PaginationProps> = ({
  currentRefinement,
  arrayOfPages,
  query
}) => (
  <Fragment>
    {arrayOfPages.map((page: number) => (
      <PageNumber
        data-test="page-number"
        page={page}
        current={currentRefinement}
        query={query}
        key={`page-${page}`}
      />
    ))}
  </Fragment>
);

export default BasicPagination;
