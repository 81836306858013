import dynamic from 'next/dynamic';
import React, { FC, Fragment, useState } from 'react';

import { convertAlgoliaFiltersToCriteriaUrl } from '@/components/alert/buyerAlertForm/utils';
import { UrlProps, URLS } from '@/constants/global';
import { generateLinkHref } from '@/utils/filters';
import { isMobileDevice } from '@/utils/responsive';

import { FiltersContextProps } from '../filtersProvider/FiltersProvider';
import { getLocationIds } from '../search/utils';
import DesktopFiltersBar from './desktopFiltersBar/DesktopFiltersBar';
import { FiltersWrapper, StickyNav } from './FiltersBar.styles';
import MobileFiltersBar from './mobileFiltersBar/MobileFiltersBar';

const FixedMobileFiltersBar = dynamic(
  () => import('./fixedMobileFiltersBar/FixedMobileFiltersBar'),
  { ssr: false }
);
const FixedDesktopFiltersBar = dynamic(
  () => import('./fixedDesktopFiltersBar/FixedDesktopFiltersBar'),
  { ssr: false }
);

const FiltersBar: FC<FiltersContextProps> = ({
  locationState,
  searchState,
  updateFilters,
  applyFilters,
  toggleSold
}) => {
  const [isSticky, setIsSticky] = useState<boolean>(false);
  const isMobile = isMobileDevice();

  const url = convertAlgoliaFiltersToCriteriaUrl(
    searchState,
    getLocationIds(locationState || [])
  );

  const link: UrlProps = {
    ...URLS.ALERT,
    ...generateLinkHref(URLS.ALERT, url)
  };

  const commonProps = {
    isSticky,
    link,
    locationState,
    searchState,
    applyFilters,
    setIsSticky,
    toggleSold,
    updateFilters
  };

  return (
    <Fragment>
      <FiltersWrapper data-test="filters-bar">
        {isMobile ? (
          <MobileFiltersBar {...commonProps} />
        ) : (
          <DesktopFiltersBar {...commonProps} />
        )}
      </FiltersWrapper>
      <StickyNav>
        {isMobile ? (
          <FixedMobileFiltersBar {...commonProps} />
        ) : (
          <FixedDesktopFiltersBar {...commonProps} />
        )}
      </StickyNav>
    </Fragment>
  );
};

export default FiltersBar;
