import { FC, Fragment, useEffect } from 'react';
import { useInstantSearch } from 'react-instantsearch';

import { sendDatalayer } from '@/utils/gtm';
import { useDebounce } from '@proprioo/salatim';

type StateResultsConnectorProps = {
  page: number;
  filters: string;
};

export const StateResultsConnector: FC<StateResultsConnectorProps> = ({
  page,
  filters
}) => {
  const { results } = useInstantSearch();
  const debouncedSearchTerm = useDebounce<{ filters: string; page: number }>(
    { filters, page },
    1000
  );

  /**
   * Trigger dataLayer push only when displayed results changes
   */
  useEffect(() => {
    sendDatalayer({
      event: 'show annonces page',
      ecommerce: {
        currencyCode: 'EUR',
        ...(results.hits.length > 0 && {
          impressions: results.hits.map((item, index) => ({
            name: item.titre,
            id: item.objectID,
            price: item.prix,
            category: item.typeBien,
            dimension1: item.codePostal,
            dimension2: page,
            list: filters,
            position: index + 1
          }))
        })
      }
    });
  }, [debouncedSearchTerm.filters, debouncedSearchTerm.page]);

  return <Fragment />;
};

export default StateResultsConnector;
