import * as yup from 'yup';

import { UserSchema } from '@proprioo/salatim';

import { CriteriaSchema } from './criteriaForm/CriteriaForm.schema';
import { ProjectSchema } from './projectForm/ProjectForm.schema';

export const BuyerAlertFormSchema = yup.object().shape({
  project: ProjectSchema,
  criteria: CriteriaSchema,
  user: UserSchema,
  promotionalConsent: yup.boolean()
});
