import { RichTextBlock } from 'prismic-reactjs';

import { TypeAgent } from '@/components/agentPage/interfaces';
import { getSoldListing } from '@/components/common/slices/soldListing/SoldListing';
import { ListingAttributes } from '@/components/listing/listing/Listing.interfaces';
import { COMPANY } from '@/constants/global';
import { CITY_DATA } from '@/utils/city';
import {
  isValidLink,
  PrismicDocumentType,
  PrismicLink,
  PrismicSlice,
  TypeColor,
  TypeHeaderImgDisplay,
  TypeSlice
} from '@/utils/prismic';
import clientPrismic from '@/utils/prismicClient';
import { colors } from '@proprioo/salatim';

import {
  ResponseAgentProps,
  ResponseFaqProps,
  ResponsePageProps
} from '../components/guide/utils';
import { fetchAgents } from '../components/virtualCard/fetch';

export type PageCta = {
  label: string;
  link: PrismicLink;
};

const colorsMap = new Map<TypeColor, string>();
colorsMap.set(TypeColor.WHITE, 'white');
colorsMap.set(TypeColor.GREEN, colors.dark.base);
colorsMap.set(TypeColor.BLUE, colors.blue.base);
colorsMap.set(TypeColor.ORANGE, colors.terracota.base);
colorsMap.set(TypeColor.PINK, colors.pink.base);

export { colorsMap as PageColors };

export type TypePageHeader = {
  hasSearchField: boolean;
  visualDisplayMode: string;
  bgColor: string;
  pressEnabled: boolean;
  cta: PageCta[];
  visual?: RichTextBlock;
  title?: string;
  subTitle?: string;
  breadCrumb?: string;
};

export type TypeStickyCta = {
  ctaLink: PrismicLink;
  ctaLabel: string;
};

export type TypePage = {
  id: string;
  headerEnabled: boolean;
  slices: PrismicSlice[];
  title: string;
  header: TypePageHeader;
  uid?: string;
  description?: string;
  ogTitle?: string;
  ogDescription?: string;
  ogImage?: RichTextBlock;
  datalayerTitle?: string;
  stickyCta?: TypeStickyCta;
  phoneNumber?: string;
};

export type TypeFaqTheme = {
  id: string;
  title: string;
  weight?: number;
};

export type TypePageFaq = {
  id: string;
  uid: string;
  title: string;
  concerned: { name: string }[];
  weight: number;
  content?: RichTextBlock[];
  theme?: TypeFaqTheme;
};

export const DEFAULT_HEADER: TypePageHeader = {
  hasSearchField: false,
  visualDisplayMode: TypeHeaderImgDisplay.FULL,
  bgColor: TypeColor.GREEN,
  pressEnabled: false,
  cta: []
};

export const DEFAULT_PAGE: TypePage = {
  id: 'YNxgTBAAACUAGsig',
  uid: 'faq',
  title: 'Proprioo : L’agence immobilière repensée pour mieux vous accompagner',
  description: 'Desc: Proprioo',
  slices: [],
  headerEnabled: true,
  header: {
    ...DEFAULT_HEADER,
    title: 'header title',
    subTitle: 'subtitle proprioo',
    hasSearchField: true,
    visualDisplayMode: TypeHeaderImgDisplay.FULL,
    bgColor: TypeColor.GREEN,
    pressEnabled: true,
    cta: [],
    breadCrumb: 'Charte'
  }
};

const buildAgentFromPrismic = ({ data }: ResponseAgentProps) => {
  const { fonction, zone, image_agent, image_dashboard } = data;

  return {
    zone,
    ...(Boolean(fonction.length) && { fonction }),
    ...(image_agent.url && { imageAgent: image_agent }),
    ...(image_dashboard.url && { imageAgent: image_agent })
  };
};

export const buildFaqFromPrismic = ({
  id,
  uid,
  data
}: ResponseFaqProps): TypePageFaq => {
  if (!data || !uid || !id) {
    throw new Error();
  }
  const { title, concerned, weight, theme, content } = data;

  return {
    id,
    uid,
    title,
    concerned,
    ...(content && { content }),
    ...(theme?.data && { theme: theme.data }),
    weight: weight || Number.MAX_SAFE_INTEGER
  } as TypePageFaq;
};

export const buildPageFromPrismic = ({
  id,
  uid,
  data
}: ResponsePageProps): TypePage => {
  if (!data || !uid || !id) {
    throw new Error();
  }

  const {
    title,
    ogTitle,
    ogDescription,
    ogImage,
    header_title,
    header_subtitle,
    header_enabled,
    header_has_search_field,
    header_visual,
    header_visual_display_mode,
    header_bg_color,
    header_press_enabled,
    header_ctas,
    header_breadcrumb,
    description,
    showStickyButton,
    stickyButtonLabel,
    stickyButtonLink,
    phoneNumber,
    body
  } = data;
  const cityData = Object.values(CITY_DATA).find(city => city.slug === uid);
  const datalayerTitle = cityData ? cityData.dataLayerTitle : uid;
  const hasStickyButton =
    showStickyButton && stickyButtonLabel && isValidLink(stickyButtonLink);

  return {
    id,
    uid,
    title: title || header_title || COMPANY,
    ...(description && { description }),
    ...(ogTitle && { ogTitle }),
    ...(ogDescription && { ogDescription }),
    ...(hasStickyButton && {
      stickyCta: {
        ctaLink: stickyButtonLink,
        ctaLabel: stickyButtonLabel
      } as TypeStickyCta
    }),
    ogImage,
    slices: [...body],
    datalayerTitle,
    headerEnabled: header_enabled,
    ...(phoneNumber && { phoneNumber }),
    header: {
      ...(header_title && { title: header_title }),
      ...(header_subtitle && { subTitle: header_subtitle }),
      hasSearchField: header_has_search_field,
      visual: header_visual,
      visualDisplayMode: header_visual_display_mode,
      bgColor: header_bg_color,
      pressEnabled: header_press_enabled,
      cta: header_ctas,
      ...(header_breadcrumb && { breadCrumb: header_breadcrumb })
    }
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getPage = (headerData: Record<string, any> = {}): TypePage => {
  return {
    ...DEFAULT_PAGE,
    header: {
      ...DEFAULT_PAGE.header,
      ...headerData
    }
  };
};

export const getSoldRealEstate = async (
  codes: string
): Promise<ListingAttributes[]> => {
  const departmentsCodes = codes
    ? codes
        .split(',')
        .filter((code: string) => {
          const number: number = parseInt(code.trim(), 10);

          return !(isNaN(number) || number == 0);
        })
        .map((code: string) => code.trim())
    : [];

  return await getSoldListing(departmentsCodes);
};

// FAQS
const getAllFaqPages = async () => {
  // Get all faqs
  const pages = await clientPrismic.getByType<ResponseFaqProps>(
    PrismicDocumentType.FAQ,
    {
      fetch: ['faq.title', 'faq.concerned', 'faq.theme', 'faq.weight'],
      fetchLinks: ['theme_faq.title', 'theme_faq.weight']
    }
  );

  if (pages.results.length) {
    return pages.results
      .filter(({ data }) => Boolean(data.title))
      .map(faqPage => buildFaqFromPrismic(faqPage));
  }

  return [];
};

// AGENTS
export const getAllAgents = async () => {
  const [prismicAgents, fetchedAgents] = await Promise.all([
    clientPrismic.getAllByType<ResponseAgentProps>(PrismicDocumentType.AGENT),
    fetchAgents()
  ]);

  const prismicAgentIds = prismicAgents.map(
    ({ uid }) => uid && parseInt(uid, 10)
  );

  const mergedAgents: TypeAgent[] = fetchedAgents.map(agent => {
    const prismicAgent = prismicAgents.find(
      prismic => parseInt(`${prismic.uid}`, 10) === agent.id
    );

    return {
      ...agent,
      ...(prismicAgent?.id && {
        ...buildAgentFromPrismic(prismicAgent)
      })
    };
  });

  if (mergedAgents.length) {
    return mergedAgents
      .filter(({ lastname, firstname, zone }) => firstname && lastname && zone)
      .filter(({ id }) => prismicAgentIds.includes(id))
      .filter(
        (agent, index, arr) =>
          arr.findIndex(dedupeAgent => dedupeAgent.id === agent.id) === index
      );
  }

  return [];
};

export const getPageDataByPageUID = async (
  pageUiD: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  preview?: any
): Promise<TypePage> => {
  const prismicData =
    (await clientPrismic.getByUID<ResponsePageProps>(
      PrismicDocumentType.PAGE,
      `${pageUiD}`,
      { ref: preview?.reference }
    )) || {};

  const pageData = buildPageFromPrismic(prismicData);

  // Find sold slice and set sold list
  const soldSlice = pageData.slices.find(
    ({ slice_type }) => slice_type === TypeSlice.SOLD
  );
  // Find faqs slice
  const faqsSlice = pageData.slices.find(
    ({ slice_type }) => slice_type === TypeSlice.FAQS
  );
  // Find agents slice
  const agentsSlice = pageData.slices.find(
    ({ slice_type }) => slice_type === TypeSlice.AGENTS
  );

  if (soldSlice) {
    soldSlice.items = await getSoldRealEstate(
      soldSlice.primary?.departmentsCode
    );
  }

  if (faqsSlice) {
    faqsSlice.items = await getAllFaqPages();
  }

  if (agentsSlice) {
    agentsSlice.items = await getAllAgents();
  }

  return { ...pageData };
};
