import React, {
  FC,
  PropsWithChildren,
  useEffect,
  useRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';

import DropdownIcon from '@/assets/icons/arrow.svg';
import { useOnClickOutside } from '@proprioo/salatim';

import {
  ApplyButton,
  Content,
  Dropdown,
  DropdownContent,
  Filter,
  IconWrapper,
  Label,
  Layout,
  Results,
  Value
} from './FilterDropdown.styles';
import { FilterTypes } from './interfaces';

export type FilterDropdownProps = {
  filterType: FilterTypes;
  isSticky: boolean;
  label: string;
  value: string;
  applyFilters(): void;
};

const FilterDropdown: FC<PropsWithChildren<FilterDropdownProps>> = ({
  children,
  filterType,
  isSticky,
  label,
  value,
  applyFilters
}) => {
  const { t } = useTranslation();

  const [isOpen, toggleOpen] = useState<boolean>(false);
  const layoutRef = useRef<HTMLDivElement>(null);
  const filterRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(filterRef, event => {
    if (!layoutRef.current?.contains(event.target as Node)) {
      toggleOpen(false);
      applyFilters();
    }
  });

  useEffect(() => {
    if (isOpen) {
      toggleOpen(false);
      applyFilters();
    }
  }, [isSticky]);

  const isLocationFilter = filterType === FilterTypes.LOCATION;

  return (
    <Layout
      data-test={`filter-layout-${filterType}`}
      fixedWidth={isLocationFilter}
      ref={layoutRef}
    >
      <Dropdown
        onClick={() => toggleOpen(!isOpen)}
        isOpen={isOpen}
        data-test={`dropdown-${filterType}`}
      >
        <DropdownContent>
          <Label data-test={`label-${filterType}`}>{label}</Label>
          {value && <Value data-test={`value-${filterType}`}>{value}</Value>}
        </DropdownContent>
        <IconWrapper isOpen={isOpen}>
          <DropdownIcon />
        </IconWrapper>
      </Dropdown>
      {isOpen && (
        <Filter ref={filterRef}>
          <Content>{children}</Content>
          <Results>
            <ApplyButton
              data-test={`apply-button-${filterType}`}
              onClick={() => {
                toggleOpen(false);
                applyFilters();
              }}
            >
              {t(isLocationFilter ? 'close' : 'apply')}
            </ApplyButton>
          </Results>
        </Filter>
      )}
    </Layout>
  );
};

export default FilterDropdown;
