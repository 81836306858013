import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import ListingTypes from '@/components/alert/listingTypes/ListingTypes';
import LocationRefinement from '@/components/common/locationRefinement/LocationRefinement';
import { DEFAULT_PROPERTIES } from '@/constants/constants';
import { getRangeState } from '@/utils/filters';
import { PRICE_MAX, SURFACE_MAX } from '@proprioo/hokkaido';

import OtherCriterias from '../criterias/OtherCriterias';
import CustomRange from '../customRange/CustomRange';
import FilterDropdown from '../filterDropdown/FilterDropdown';
import { FilterTypes } from '../filterDropdown/interfaces';
import { FiltersContextProps } from '../filtersProvider/FiltersProvider';
import { OtherCriteriasProps } from '../search/interfaces';
import { getLocationNames } from '../search/utils';
import {
  countOtherLabel,
  defaultFormat,
  extractOtherCriterias,
  formatRangePrice,
  formatSurface,
  joinValues
} from './Filters.utils';

type FiltersProps = FiltersContextProps & {
  isSticky: boolean;
  insertLocation?: boolean;
};

export const Filters: FC<FiltersProps> = ({
  insertLocation,
  isSticky,
  locationState,
  searchState,
  applyFilters,
  updateFilters
}) => {
  const { t } = useTranslation();

  const locationNames = getLocationNames(locationState || []);
  const listingTypes =
    (searchState[FilterTypes.TYPE] as string[]) || DEFAULT_PROPERTIES;
  const translatedListingTypes = listingTypes.map(type => t(type));

  const updateOther = (other: OtherCriteriasProps) => {
    updateFilters({ ...searchState, other });
  };

  const criteriaLabel = extractOtherCriterias(searchState.other);
  const countCriteria = countOtherLabel(criteriaLabel);

  return (
    <Fragment>
      {insertLocation && (
        <FilterDropdown
          applyFilters={applyFilters}
          data-test={`filter-dropdown-${FilterTypes.LOCATION}`}
          filterType={FilterTypes.LOCATION}
          isSticky={isSticky}
          label={t(FilterTypes.LOCATION)}
          value={joinValues(locationNames)}
        >
          <LocationRefinement
            applyFilters={applyFilters}
            locationState={locationState}
            updateFilters={updateFilters}
            withInputBase={true}
          />
        </FilterDropdown>
      )}
      <FilterDropdown
        applyFilters={applyFilters}
        data-test={`filter-dropdown-${FilterTypes.TYPE}`}
        filterType={FilterTypes.TYPE}
        isSticky={isSticky}
        label={t(FilterTypes.TYPE)}
        value={joinValues(translatedListingTypes)}
      >
        <ListingTypes
          updateFilters={updateFilters}
          values={searchState[FilterTypes.TYPE] as string[]}
        />
      </FilterDropdown>
      <FilterDropdown
        applyFilters={applyFilters}
        data-test={`filter-dropdown-${FilterTypes.ROOMS}`}
        filterType={FilterTypes.ROOMS}
        isSticky={isSticky}
        label={t(FilterTypes.ROOMS)}
        value={defaultFormat(searchState.nbPieces)}
      >
        <CustomRange
          attribute={FilterTypes.ROOMS}
          maxLabel={'roomsMax'}
          minLabel={'roomsMin'}
          state={getRangeState(searchState, FilterTypes.ROOMS)}
          updateFilters={updateFilters}
        />
      </FilterDropdown>
      <FilterDropdown
        applyFilters={applyFilters}
        data-test={`filter-dropdown-${FilterTypes.BEDROOMS}`}
        filterType={FilterTypes.BEDROOMS}
        isSticky={isSticky}
        label={t(FilterTypes.BEDROOMS)}
        value={defaultFormat(searchState.nbBedrooms)}
      >
        <CustomRange
          attribute={FilterTypes.BEDROOMS}
          maxLabel={'nbBedroomsMax'}
          minLabel={'nbBedroomsMin'}
          state={getRangeState(searchState, FilterTypes.BEDROOMS)}
          updateFilters={updateFilters}
        />
      </FilterDropdown>
      <FilterDropdown
        applyFilters={applyFilters}
        data-test={`filter-dropdown-${FilterTypes.PRICE}`}
        filterType={FilterTypes.PRICE}
        isSticky={isSticky}
        label={t(FilterTypes.PRICE)}
        value={formatRangePrice(searchState.prix)}
      >
        <CustomRange
          attribute={FilterTypes.PRICE}
          maxLabel={'budgetMax'}
          maxSliderValue={PRICE_MAX}
          minLabel={'budgetMin'}
          state={getRangeState(searchState, FilterTypes.PRICE)}
          suffix={'euro'}
          updateFilters={updateFilters}
        />
      </FilterDropdown>
      <FilterDropdown
        applyFilters={applyFilters}
        data-test={`filter-dropdown-${FilterTypes.SURFACE}`}
        filterType={FilterTypes.SURFACE}
        isSticky={isSticky}
        label={t(FilterTypes.SURFACE)}
        value={formatSurface(searchState.surface)}
      >
        <CustomRange
          attribute={FilterTypes.SURFACE}
          maxLabel={'surfaceMax'}
          maxSliderValue={SURFACE_MAX}
          minLabel={'surfaceMin'}
          state={getRangeState(searchState, FilterTypes.SURFACE)}
          suffix={'squareFeet'}
          updateFilters={updateFilters}
        />
      </FilterDropdown>
      <FilterDropdown
        applyFilters={applyFilters}
        data-test={`filter-dropdown-${FilterTypes.OTHER}`}
        filterType={FilterTypes.OTHER}
        isSticky={isSticky}
        label={t('otherCriteriaLabel')}
        value={
          countCriteria
            ? t('otherCriteria', {
                count: countCriteria
              })
            : ''
        }
      >
        <OtherCriterias updateValue={updateOther} other={searchState.other} />
      </FilterDropdown>
    </Fragment>
  );
};

export default Filters;
