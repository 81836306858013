import styled from 'styled-components';

import { breakpoints, fonts } from '@proprioo/salatim';

export const Layout = styled.div`
  font-size: ${fonts.small};
  font-weight: 500;

  @media (min-width: ${breakpoints.base}px) {
    font-size: ${fonts.base};
  }
`;
