import styled from 'styled-components';

import { breakpoints, Container, convertPxToRem } from '@proprioo/salatim';

export const SearchContainer = styled(Container)`
  position: relative;
`;

export const HitsWrapper = styled.div`
  position: relative;
  z-index: 0;
  margin-top: ${convertPxToRem(32)};
  padding-bottom: ${convertPxToRem(32)};

  @media (min-width: ${breakpoints.base}px) {
    margin-top: ${convertPxToRem(56)};
  }
`;

export const SearchBottom = styled.div`
  margin: ${convertPxToRem(48)} 0;
`;
