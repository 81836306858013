import { useTranslation } from 'react-i18next';

import { FilterTypes } from '@/components/search/filterDropdown/interfaces';
import { joinValues } from '@/components/search/filters/Filters.utils';
import { AlgoliaSearchState } from '@/components/search/search/interfaces';
import { DEFAULT_PROPERTIES } from '@/constants/constants';

type GeneratedTitles = {
  headerTitle: string;
  pageTitle: string;
};

export const useGenerateTitles = (
  state: AlgoliaSearchState,
  locations: string[]
): GeneratedTitles => {
  const { t } = useTranslation();

  const propertyTypesState = state[FilterTypes.TYPE] || DEFAULT_PROPERTIES;
  const propertyTypesTranslated = joinValues(
    propertyTypesState.map(type => t(type)),
    ` ${t('or')} `
  );

  const formattedLocations =
    locations.length > 3
      ? `${locations.slice(0, 3).join(', ')}...`
      : locations.join(', ');

  const properties = formattedLocations
    ? propertyTypesTranslated.concat(` - ${formattedLocations}`)
    : propertyTypesTranslated;

  return {
    headerTitle: t('yourSearch', { properties }),
    pageTitle: t('yourSearch', { properties })
  };
};
