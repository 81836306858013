import { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import Bell from '@/assets/icons/bell-search.svg';
import CustomLink from '@/components/common/customLink/CustomLink';
import LocationRefinement from '@/components/common/locationRefinement/LocationRefinement';
import Filters from '@/components/search/filters/Filters';
import { FiltersContextProps } from '@/components/search/filtersProvider/FiltersProvider';
import { UrlProps } from '@/constants/global';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { Button, ButtonAppearance, ButtonSizes } from '@proprioo/salatim';

import {
  AlertButton,
  DesktopWrapper,
  FiltersList,
  SearchBar
} from './DesktopFiltersBar.styles';

export type DesktopFiltersBarProps = FiltersContextProps & {
  isSticky: boolean;
  link: UrlProps;
  setIsSticky(isSticky: boolean): void;
};

const DesktopFiltersBar: FC<DesktopFiltersBarProps> = ({
  isSticky,
  link,
  locationState,
  searchState,
  applyFilters,
  toggleSold,
  updateFilters,
  setIsSticky
}) => {
  const { t } = useTranslation();
  const filtersRef = useRef<HTMLDivElement>(null);

  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y < -72) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    },
    [],
    // @ts-ignore library is not handling null ref but not a problem for us
    filtersRef
  );

  return (
    <DesktopWrapper data-test="desktop-filters">
      <SearchBar>
        <LocationRefinement
          applyFilters={applyFilters}
          locationState={locationState}
          updateFilters={updateFilters}
        />
        <CustomLink link={link} dataTest="link-alert-button">
          <AlertButton>
            <Button
              label={t('alertButton')}
              size={ButtonSizes.SMALL}
              appearance={ButtonAppearance.SECONDARY}
              icon={<Bell />}
            />
          </AlertButton>
        </CustomLink>
      </SearchBar>
      <FiltersList ref={filtersRef}>
        <Filters
          applyFilters={applyFilters}
          isSticky={isSticky}
          searchState={searchState}
          toggleSold={toggleSold}
          updateFilters={updateFilters}
        />
      </FiltersList>
    </DesktopWrapper>
  );
};

export default DesktopFiltersBar;
