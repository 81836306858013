import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import Arrow from '@/assets/icons/arrow-rounded.svg';
import {
  SliceFooter,
  SliceHeader,
  SliceWrapper
} from '@/components/common/slices/Slices.styles';
import { SoldListingContent } from '@/components/common/slices/soldListing/SoldListing.styles';
import { ListingAttributes } from '@/components/listing/listing/Listing.interfaces';
import { formatCriterias } from '@/components/listing/utils';
import { buildTags } from '@/components/search/customHits/utils';
import { URLS } from '@/constants/global';
import { GraphOperationName, graphRequest } from '@/utils/http';
import { isMobileDevice } from '@/utils/responsive';
import { isExclusiveMandate, MandateType } from '@proprioo/hokkaido';
import { Button, ButtonAppearance, Container, H2 } from '@proprioo/salatim';

import CustomLink from '../../customLink/CustomLink';
import GridLine from '../../gridLine/GridLine';
import { GridElement } from '../../gridLine/GridLine.styles';
import ListingCard from '../../listingCard/ListingCard';
import { SOLD_LISTING_QUERY } from './SoldListing.query';

export type SoldListingProps = {
  soldListingsForShowcase?: ListingAttributes[];
  blockTitle?: string;
};

export const extractListingToCard = (
  {
    nbRooms,
    surface,
    nbBedrooms,
    pictures,
    id,
    type,
    title,
    price,
    city,
    postalCode,
    mandateInfo,
    finalSaleDate,
    publicationDate,
    hasCellarOrAnnex,
    hasLift,
    hasOutdoorSpace,
    hasParking,
    hasPool,
    withoutRenovationWork,
    groundFloor
  }: ListingAttributes,
  isSold = false
) => {
  const [firstPicture] = pictures;

  const isExclusive = Boolean(
    mandateInfo?.mandateType === MandateType.FULL_EXCLUSIVITY
  );

  return {
    pictures: firstPicture ? [firstPicture] : [],
    type,
    id: `${id}`,
    uri: '',
    title,
    tags: buildTags(nbRooms, surface, nbBedrooms),
    isSold: true,
    isExclusive: isSold
      ? isExclusive
      : Boolean(
          mandateInfo &&
            isExclusiveMandate(
              mandateInfo.mandateType,
              mandateInfo.exclusivityEndDate
            )
        ),
    price,
    city,
    hasCellarOrAnnex,
    hasLift,
    hasOutdoorSpace,
    hasParking,
    hasPool,
    withoutRenovationWork,
    groundFloor,
    postalCode,
    firstPublication: publicationDate,
    ...(finalSaleDate && { finalSaleDate })
  };
};

export const getSoldListing = async (postalCodePrefixes: string[]) => {
  const {
    data: { soldListingsForShowcase }
  } = await graphRequest<{ soldListingsForShowcase: ListingAttributes[] }>(
    GraphOperationName.SOLD_LISTING,
    SOLD_LISTING_QUERY,
    { postalCodePrefixes },
    true
  );

  return soldListingsForShowcase;
};

const SoldListing: FC<SoldListingProps> = ({
  soldListingsForShowcase = [],
  blockTitle
}) => {
  const { t } = useTranslation();
  const isMobile = isMobileDevice();

  const soldListingMax = soldListingsForShowcase.slice(0, 3);
  const linkSold = {
    ...URLS.SEARCH,
    href: `${URLS.SEARCH.href}?status=SOLD`
  };

  return soldListingMax.length === 3 ? (
    <SliceWrapper>
      <Container>
        <SliceHeader>
          <H2
            title={
              blockTitle ||
              t('ourLastSelled', {
                interpolation: { escapeValue: false }
              })
            }
          />
          {!isMobile && (
            <CustomLink link={linkSold}>
              <Button
                label={t('processSoldButton')}
                appearance={ButtonAppearance.BASIC}
                icon={<Arrow />}
              />
            </CustomLink>
          )}
        </SliceHeader>
        <SoldListingContent>
          <GridLine nbLines={3}>
            {soldListingMax.map((listing, index) => (
              <GridElement key={index}>
                <ListingCard
                  {...extractListingToCard(listing, true)}
                  {...formatCriterias(listing)}
                  data-test="listing-card"
                />
              </GridElement>
            ))}
          </GridLine>
        </SoldListingContent>
        {isMobile && (
          <SliceFooter>
            <CustomLink link={linkSold}>
              <Button
                label={t('processSoldButton')}
                appearance={ButtonAppearance.BASIC}
                icon={<Arrow />}
              />
            </CustomLink>
          </SliceFooter>
        )}
      </Container>
    </SliceWrapper>
  ) : (
    <Fragment />
  );
};

export default SoldListing;
