import styled, { css } from 'styled-components';

import {
  breakpoints,
  colors,
  convertPxToRem,
  radius,
  shadows
} from '@proprioo/salatim';

import { DesktopWrapper } from './desktopFiltersBar/DesktopFiltersBar.styles';

export const fixedBarTop = `-${convertPxToRem(72)}`;

export const FiltersWrapper = styled.div`
  margin-top: -${convertPxToRem(30)};
  min-height: ${convertPxToRem(66)};
  position: relative;

  @media (min-width: ${breakpoints.base}px) {
    margin-top: -${convertPxToRem(74)};
  }

  ${DesktopWrapper} {
    display: none;

    @media (min-width: ${breakpoints.base}px) {
      display: block;
    }
  }
`;

export const FixedFiltersBar = styled.div<{ isSticky: boolean }>`
  position: fixed;
  z-index: 10;
  top: ${fixedBarTop};
  left: 0;
  transition: top 0.2s;
  height: ${convertPxToRem(72)};
  width: 100%;
  background: ${colors.background};
  box-shadow: ${shadows.level5};

  ${({ isSticky }) =>
    isSticky &&
    css`
      top: 0;
    `};
`;

export const FixedAlertButton = styled.span`
  background: ${colors.terracota.base};
  width: ${convertPxToRem(46)};
  height: ${convertPxToRem(46)};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${radius.rounded};
  color: white;
  margin-top: ${convertPxToRem(10)};
  margin-right: ${convertPxToRem(10)};
  transition: 0.2s transform ease-out;

  @media (min-width: ${breakpoints.small}px) {
    margin-right: ${convertPxToRem(18)};
  }

  @media (min-width: ${breakpoints.base}px) {
    margin-right: 0;
  }

  svg {
    width: ${convertPxToRem(32)};
    height: ${convertPxToRem(32)};
  }

  :hover {
    transform: scale(0.9);
  }

  :active {
    background: ${colors.terracota.base80};
  }
`;

export const StickyNav = styled.div`
  position: sticky;
  top: 0;
  z-index: 10;
`;
