import React, { FC, Fragment, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Dropdown from '@/assets/icons/arrow.svg';
import Bell from '@/assets/icons/bell-search.svg';
import CustomLink from '@/components/common/customLink/CustomLink';
import FiltersModal from '@/components/search/filtersModal/FiltersModal';
import { FiltersContextProps } from '@/components/search/filtersProvider/FiltersProvider';
import Modal from '@/components/search/modal/Modal';
import { UrlProps } from '@/constants/global';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';

import { FixedAlertButton } from '../FiltersBar.styles';
import { Layout, SearchButton } from './MobileFiltersBar.styles';

export type MobileFiltersBarProps = FiltersContextProps & {
  link: UrlProps;
  setIsSticky(isSticky: boolean): void;
};

const MobileFiltersBar: FC<MobileFiltersBarProps> = ({
  link,
  locationState,
  searchState,
  applyFilters,
  setIsSticky,
  toggleSold,
  updateFilters
}) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const layoutRef = useRef<HTMLDivElement>(null);

  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y < -66) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    },
    [],
    // @ts-ignore library is not handling null ref but not a problem for us
    layoutRef
  );

  const toggleOpen = () => {
    document.body.style.overflow = !isOpen ? 'hidden' : 'unset';
    setIsOpen(!isOpen);
  };

  return (
    <Fragment>
      <Layout data-test="mobile-filters-bar" ref={layoutRef}>
        <SearchButton
          data-test={`search-button-${isOpen ? 'open' : 'close'}`}
          isOpen={isOpen}
          onClick={toggleOpen}
        >
          {t('mySearch')}
          <Dropdown />
        </SearchButton>
        <CustomLink link={link}>
          <FixedAlertButton>
            <Bell />
          </FixedAlertButton>
        </CustomLink>
      </Layout>
      {isOpen && (
        <Modal isOpen={isOpen}>
          <FiltersModal
            applyFilters={applyFilters}
            isOpen={isOpen}
            locationState={locationState}
            searchState={searchState}
            toggleIsOpen={toggleOpen}
            toggleSold={toggleSold}
            updateFilters={updateFilters}
          />
        </Modal>
      )}
    </Fragment>
  );
};

export default MobileFiltersBar;
