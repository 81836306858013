import styled, { css } from 'styled-components';

import {
  breakpoints,
  colors,
  convertPxToRem,
  fonts,
  paragraphs,
  radius,
  shadows
} from '@proprioo/salatim';

const MediumLayoutFixedWidth = convertPxToRem(240);
const LayoutFixedWidth = convertPxToRem(280);

export const Dropdown = styled.div<{ isOpen: boolean }>`
  height: ${convertPxToRem(72)};
  display: flex;
  align-items: center;
  padding: ${convertPxToRem(24)} ${convertPxToRem(16)};
  cursor: pointer;

  ${({ isOpen }) =>
    isOpen &&
    css`
      &:before {
        background: ${colors.terracota.base};
        height: ${convertPxToRem(1)};
        width: 100%;
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
      }

      ${Value} {
        color: ${colors.terracota.base};
      }

      ${IconWrapper} {
        svg,
        path {
          fill: ${colors.terracota.base};
        }
      }
    `}
`;

export const ApplyButton = styled.div`
  ${paragraphs.tab};
  cursor: pointer;
  color: ${colors.dark.base};
  padding: ${convertPxToRem(4)} 0;
`;

export const Label = styled.p`
  ${paragraphs.label};
  font-weight: 500;
  margin: 0;
  color: ${colors.grey.base};
  line-height: ${convertPxToRem(16)};
`;

export const Filter = styled.div`
  position: absolute;
  z-index: 4;
  top: ${convertPxToRem(72)};
  background: white;
  box-shadow: ${shadows.level2};
  border-radius: 0 0 ${radius.big} ${radius.big};
  min-width: 100%;
`;

export const Layout = styled.div<{
  fixedWidth: boolean;
}>`
  position: relative;

  &:first-of-type {
    ${Dropdown} {
      padding-left: ${convertPxToRem(24)};
    }
  }

  &:last-of-type {
    position: unset;

    ${Filter} {
      right: 0;
    }
  }

  ${({ fixedWidth }) =>
    fixedWidth
      ? css`
          width: ${MediumLayoutFixedWidth};

          @media (min-width: ${breakpoints.large}px) {
            width: ${LayoutFixedWidth};
          }
        `
      : css`
          flex: 1;
          max-width: calc(100% / 5);
        `}
`;

export const DropdownContent = styled.div`
  flex: 1;
  max-width: calc(100% - ${convertPxToRem(32)});
`;

export const IconWrapper = styled.div<{ isOpen: boolean }>`
  height: ${convertPxToRem(32)};

  svg {
    width: ${convertPxToRem(32)};
    height: ${convertPxToRem(32)};
    transform: rotate(90deg);
    transition: transform 0.3s ease 0s;

    ${({ isOpen }) =>
      isOpen &&
      css`
        transform: rotate(-90deg);
      `}
  }
`;

export const Value = styled.p`
  margin: 0;
  font-size: ${fonts.small};
  color: ${colors.dark.base};
  line-height: ${convertPxToRem(18)};
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Content = styled.div`
  padding: ${convertPxToRem(32)} ${convertPxToRem(24)};
  position: relative;
  z-index: 9;
  background-image: linear-gradient(
    to bottom,
    ${colors.grey.base10},
    white 14%
  );
`;

export const Results = styled.div`
  display: flex;
  justify-content: center;
  background: ${colors.pink.base};
  padding: ${convertPxToRem(8)} ${convertPxToRem(24)};
  border-radius: 0 0 ${radius.big} ${radius.big};
`;
