import styled, { css } from 'styled-components';

import { getArrayOfLength } from '@proprioo/hokkaido';
import { breakpoints, convertPxToRem } from '@proprioo/salatim';

export const Grid = styled.div<{ nbLines: number }>`
  display: grid;

  ${({ nbLines }) =>
    css`
      grid-template-columns: ${getArrayOfLength(nbLines).map(() => ' 1fr')};
    `};

  @media (min-width: ${breakpoints.base}px) {
    grid-gap: ${convertPxToRem(32)};
  }
`;

export const GridElement = styled.div`
  padding: 0 ${convertPxToRem(16)};

  :first-child {
    padding-left: ${convertPxToRem(16)};

    @media (min-width: ${breakpoints.xsmall}px) {
      padding-left: ${convertPxToRem(24)};
    }

    @media (min-width: ${breakpoints.small}px) {
      padding-left: ${convertPxToRem(40)};
    }
  }

  :last-child {
    padding-right: ${convertPxToRem(16)};

    @media (min-width: ${breakpoints.xsmall}px) {
      padding-right: ${convertPxToRem(24)};
    }

    @media (min-width: ${breakpoints.small}px) {
      padding-right: ${convertPxToRem(40)};
    }
  }

  :nth-child(n) {
    @media (min-width: ${breakpoints.base}px) {
      padding: 0;
    }
  }
`;

export const GridWrapper = styled.div`
  overflow-y: auto;
  position: relative;
  margin: 0 -${convertPxToRem(16)};

  @media (min-width: ${breakpoints.xsmall}px) {
    margin: 0 -${convertPxToRem(24)};
  }

  @media (min-width: ${breakpoints.small}px) {
    margin: 0 -${convertPxToRem(40)};
  }

  @media (min-width: ${breakpoints.base}px) {
    margin: 0;
    overflow: hidden;
  }
`;
