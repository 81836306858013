import styled from 'styled-components';

import { Layout } from '@/components/search/filterDropdown/FilterDropdown.styles';
import { colors, convertPxToRem, radius, shadows } from '@proprioo/salatim';

export const AlertButton = styled.span`
  margin-left: ${convertPxToRem(24)};
  position: relative;
  z-index: 1;
`;

export const DesktopWrapper = styled.div`
  background: ${colors.background};
  border-radius: ${radius.normal};
  box-shadow: ${shadows.level5};
`;

export const FiltersList = styled.div`
  position: relative;
  display: flex;
  border-top: ${convertPxToRem(1)} solid ${colors.blue.base20};
  min-height: ${convertPxToRem(72)};

  ${Layout} + ${Layout} {
    border-left: ${convertPxToRem(1)} solid ${colors.blue.base20};
  }
`;

export const SearchBar = styled.div`
  display: flex;
  padding: ${convertPxToRem(20)} ${convertPxToRem(18)};
  position: relative;
`;
