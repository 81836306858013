const MAX_PAGES_SIMPLE = 8;
const PAGES_LANDMARK = 4;

export const isComplexPagination = (nbPages: number) =>
  nbPages > MAX_PAGES_SIMPLE;

export const isBeforeLandmark = (current: number) => current < PAGES_LANDMARK;

export const isAfterEndLandmark = (current: number, nbPages: number) =>
  current > nbPages - (PAGES_LANDMARK - 1);

export const isAroundCurrentRefinement = (current: number, page: number) =>
  page > current - 2 && page < current + 2;

export const atStart = (page: number) => page <= PAGES_LANDMARK;
