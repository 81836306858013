import * as yup from 'yup';

const RangeSchema = yup.object({
  min: yup.number().nullable().min(0),
  max: yup
    .number()
    .when('min', ([value]) =>
      value
        ? yup.number().nullable().min(1).moreThan(yup.ref('min'))
        : yup.number().nullable().min(1)
    )
});

const RoomsRangeSchema = yup.object({
  min: RangeSchema.fields.min,
  max: yup.number().when('min', ([value]) =>
    value
      ? yup
          .number()
          .nullable()
          .min(yup.ref('min') as unknown as number)
      : yup.number().nullable().min(1)
  )
});

const BedroomsRangeSchema = yup.object({
  min: yup.number().nullable().min(0),
  max: yup.number().when('min', ([value]) =>
    value
      ? yup
          .number()
          .nullable()
          .min(yup.ref('min') as unknown as number)
      : yup.number().nullable().min(0)
  )
});

export const CriteriaSchema = yup.object({
  locationState: yup
    .array()
    .of(
      yup.object({
        id: yup.string(),
        name: yup.string(),
        geom: yup.array().of(yup.number())
      })
    )
    .min(1),
  propertyTypes: yup.object({
    flat: yup
      .boolean()
      .when('house', ([value], schema) =>
        !value ? yup.boolean().oneOf([true]) : schema
      ),
    house: yup.boolean()
  }),
  rooms: RoomsRangeSchema,
  nbBedrooms: BedroomsRangeSchema,
  surface: RangeSchema,
  budget: RangeSchema,
  land: RangeSchema,
  other: yup.object({
    hasPool: yup.boolean().nullable(),
    hasOutdoorSpace: yup.boolean().nullable(),
    hasLift: yup.boolean().nullable(),
    hasParking: yup.boolean().nullable(),
    hasCellarOrAnnex: yup.boolean().nullable(),
    groundFloor: yup.boolean().nullable(),
    withoutRenovationWork: yup.boolean().nullable()
  })
});
