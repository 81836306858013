import singletonRouter from 'next/router';
import React, { FC, useEffect, useMemo, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Configure,
  InstantSearch,
  InstantSearchSSRProvider
} from 'react-instantsearch';
import { createInstantSearchRouterNext } from 'react-instantsearch-router-nextjs';

import { getBreadcrumbElements } from '@/components/common/breadcrumb/Breadcrumb';
import MainHeader from '@/components/common/mainHeader/MainHeader';
import { URLS } from '@/constants/global';
import { CustomSearchProps } from '@/pages/nosannonces';
import { searchStateToUrl } from '@/utils/algolia';
import { buildFiltersFromQuery, generateLinkHref } from '@/utils/filters';
import { sendDatalayer, TITLE_DATALAYER } from '@/utils/gtm';
import { TypePageHeader } from '@/utils/page';
import { ArbitraryObject } from '@/utils/test-utils';
import { ListingStatus } from '@proprioo/hokkaido';

import CustomHits from '../customHits/CustomHits';
import {
  NbHitsContainer,
  SearchTop,
  ToggleWrapper
} from '../customHits/CustomHits.styles';
import FiltersBar from '../filtersBar/FiltersBar';
import NbHits from '../nbHits/NbHits';
import Pagination from '../pagination/Pagination';
import StateResultsConnector from '../StateResultConnector/StateResultConnector';
import { ALGOLIA_INDEX, DEFAULT_ALGOLIA } from './clientAlgolia';
import { AlgoliaSearchState } from './interfaces';
import { reducerSearch, SearchActions } from './Search.reducer';
import { HitsWrapper, SearchBottom, SearchContainer } from './Search.styles';
import Toggle from './Toggle';
import {
  generateLocationParam,
  generateSearchState,
  getLocationGeoms,
  getLocationIds,
  getStateFromFilters
} from './utils';

type SearchProps = CustomSearchProps & {
  headerData: TypePageHeader;
};

export const Search: FC<SearchProps> = ({
  headerData,
  hitsPerPage,
  initialLocationState: locationState,
  searchState,
  serverState,
  serverUrl
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    sendDatalayer({
      path: singletonRouter.asPath,
      title: TITLE_DATALAYER.LISTINGS
    });
  }, []);

  const search = useMemo(() => getStateFromFilters(searchState), [searchState]);
  const filters = useMemo(() => buildFiltersFromQuery(search), [search]);

  const { locationGeoms, locationIds } = useMemo(
    () => ({
      locationIds: getLocationIds(locationState),
      locationGeoms: getLocationGeoms(locationState).flat()
    }),
    [locationState]
  );

  const [
    { temporaryStateSearch, temporaryLocationState },
    updateSearchReducer
  ] = useReducer(reducerSearch, {
    temporaryStateSearch: searchState,
    temporaryLocationState: locationIds
  });

  const updateSearch = (search: ArbitraryObject, locationIds: string[]) => {
    const params = searchStateToUrl(search);
    const locationParams = generateLocationParam(locationIds);
    const { href } = generateLinkHref(
      URLS.SEARCH,
      params.concat(locationParams)
    );

    singletonRouter.push(href, href, { shallow: true });
  };

  const updateFilters = (partialState: AlgoliaSearchState) => {
    const temporaryState = getStateFromFilters(temporaryStateSearch);

    const stateSearch = generateSearchState(
      { ...temporaryState, ...partialState },
      hitsPerPage
    );

    updateSearchReducer(
      SearchActions.updateTemporary({
        temporaryStateSearch: stateSearch,
        temporaryLocationState:
          (partialState?.localisation as string[]) || temporaryLocationState
      })
    );
  };

  const toggleSold = (checked: boolean) => {
    const status = checked ? ListingStatus.SOLD : ListingStatus.PUBLISHED;
    const currentSearch = generateSearchState(
      { ...search, status },
      hitsPerPage
    );

    updateFilters({ status });
    updateSearch(currentSearch, temporaryLocationState);
  };

  const applyFilters = () => {
    const currentSearch = generateSearchState(search, hitsPerPage);

    if (
      temporaryStateSearch.filters !== currentSearch.filters ||
      JSON.stringify(temporaryLocationState) !== JSON.stringify(locationIds)
    ) {
      updateSearch(temporaryStateSearch, temporaryLocationState);
    }
  };

  return (
    <InstantSearchSSRProvider
      {...(serverState && {
        initialResults: {
          [ALGOLIA_INDEX]: {
            results: serverState.initialResults[ALGOLIA_INDEX].results,
            state: searchState
          }
        }
      })}
    >
      <InstantSearch
        {...DEFAULT_ALGOLIA}
        routing={{
          router: createInstantSearchRouterNext({
            /**
             * If we play with backward or or forward buttons
             * The query is updated visually but not triggered in our components
             * We override the default behavior to be sure query is triggered in our side
             * @see https://www.algolia.com/doc/api-reference/widgets/instantsearch-next-router/react-hooks/#widget-param-beforepopstate
             */
            beforePopState: ({ state, ownBeforePopState }) =>
              ownBeforePopState(state),
            serverUrl,
            singletonRouter
          })
        }}
        onStateChange={() => {
          /**
           * Do nothing
           * This method is triggered on state changes
           * We do not want this behaviour because we are using a custom method (it refreshes the query)
           * @see https://www.algolia.com/doc/api-reference/widgets/instantsearch/react-hooks/#widget-param-onstatechange
           */
        }}
      >
        <Configure
          attributesToHighlight={[]}
          filters={filters}
          hitsPerPage={hitsPerPage}
          insidePolygon={locationGeoms.length ? locationGeoms : undefined}
          page={searchState.page - 1}
        />
        <StateResultsConnector page={searchState.page} filters={filters} />
        <MainHeader
          breadcrumbElements={getBreadcrumbElements(
            headerData.breadCrumb || ''
          )}
          headerData={headerData}
          showBreadcrumb={!!headerData.breadCrumb}
        />
        <SearchContainer>
          <FiltersBar
            applyFilters={applyFilters}
            locationState={locationState}
            searchState={search}
            toggleSold={toggleSold}
            updateFilters={updateFilters}
          />
        </SearchContainer>
        <SearchContainer>
          <HitsWrapper data-test="listing-results">
            <SearchTop>
              <NbHitsContainer>
                <NbHits />
              </NbHitsContainer>
              <ToggleWrapper>
                <Toggle
                  checked={search.status === ListingStatus.SOLD}
                  id="sold-listing-toggle"
                  label={t('seeSold')}
                  name="sold-listing-toggle"
                  onChange={checked => toggleSold(checked)}
                />
              </ToggleWrapper>
            </SearchTop>
            <CustomHits locationState={locationState} searchState={search} />
            <SearchBottom>
              <Pagination
                currentRefinement={searchState.page}
                filters={filters}
                hitsPerPage={hitsPerPage}
                locationIds={locationIds}
              />
            </SearchBottom>
          </HitsWrapper>
        </SearchContainer>
      </InstantSearch>
    </InstantSearchSSRProvider>
  );
};

export default Search;
