import styled from 'styled-components';

import {
  breakpoints,
  colors,
  convertPxToRem,
  radius,
  shadows
} from '@proprioo/salatim';

export const Layout = styled.div``;

export const InputWrapper = styled.div`
  display: flex;

  > div {
    width: 100%;

    & + div {
      margin-left: ${convertPxToRem(16)};

      @media (min-width: ${breakpoints.base}px) {
        margin-left: ${convertPxToRem(12)};
      }
    }

    @media (min-width: ${breakpoints.base}px) {
      min-width: ${convertPxToRem(142)};
    }
  }

  input[type='number'] {
    padding-right: ${convertPxToRem(16)};
  }
`;

export const RheoStateWrapper = styled.div`
  margin-top: ${convertPxToRem(32)};

  .DefaultProgressBar_progressBar {
    background-color: ${colors.terracota.base};
    position: absolute;
  }

  .DefaultProgressBar_background__horizontal {
    height: ${convertPxToRem(4)};
    top: -${convertPxToRem(2)};
  }

  .DefaultHandle_handle {
    width: ${convertPxToRem(16)};
    height: ${convertPxToRem(16)};
    background: white;
    border: ${convertPxToRem(2)} solid ${colors.terracota.base};
    box-sizing: border-box;
    box-shadow: ${shadows.level2};
    border-radius: ${radius.rounded};
    outline: none;
    z-index: 2;
  }

  .DefaultHandle_handle__horizontal {
    margin-left: -${convertPxToRem(12)};
    top: -${convertPxToRem(5)};
  }

  .DefaultProgressBar_progressBar,
  .DefaultHandle_handle {
    cursor: pointer;
  }

  .DefaultBackground {
    background-color: ${colors.blue.base20};
    height: ${convertPxToRem(4)};
    width: 100%;
    border-radius: ${convertPxToRem(2)};
    position: relative;
  }

  .DefaultBackground_background__horizontal {
    height: ${convertPxToRem(4)};
    top: -${convertPxToRem(2)};
    left: -${convertPxToRem(2)};
    bottom: ${convertPxToRem(4)};
    width: 100%;
    border-radius: ${convertPxToRem(4)};
  }

  .rheostat {
    position: relative;
    overflow: visible;
    width: 100%;
  }

  @media (min-width: 1128px) {
    .autoAdjustVerticalPosition {
      top: ${convertPxToRem(12)};
    }
  }

  .rheostat__vertical {
    height: 100%;
  }

  .handleContainer {
    height: ${convertPxToRem(15)};
    top: -${convertPxToRem(3)};
    left: -${convertPxToRem(2)};
    bottom: ${convertPxToRem(4)};
    width: 100%;
    position: absolute;
  }
`;
