import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import ArrowLeft from '@/assets/icons/arrow-reverse.svg';

import { PaginationProps } from '../interfaces';
import PageNumber from '../pageNumber/PageNumber';
import { Separator } from '../Pagination.styles';
import { isAfterEndLandmark } from '../utils';

const EndPagination: FC<PaginationProps> = ({
  currentRefinement,
  nbPages,
  arrayOfPages,
  query
}) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <PageNumber
        data-test="previous"
        icon={<ArrowLeft />}
        page={currentRefinement - 1}
        current={currentRefinement}
        query={query}
      />
      <PageNumber
        data-test="first"
        page={1}
        current={currentRefinement}
        query={query}
      />
      <Separator>{t('points')}</Separator>
      {arrayOfPages.map(
        (page: number) =>
          isAfterEndLandmark(page, nbPages - 1) && (
            <PageNumber
              data-test="page-number"
              page={page}
              current={currentRefinement}
              key={`page-${page}`}
              query={query}
            />
          )
      )}
    </Fragment>
  );
};

export default EndPagination;
