import styled from 'styled-components';

import { LogoContainer } from '@/components/app/navbar/Navbar.styles';
import {
  BagossFont,
  colors,
  Container,
  convertPxToRem,
  paragraphs,
  shadows
} from '@proprioo/salatim';

export const Layout = styled.nav`
  position: fixed;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: white;

  ${Container} {
    width: 100%;
  }
`;

export const GlobalContainer = styled.div`
  overflow: auto;
  height: calc(100% - ${convertPxToRem(96)});
  width: 100%;
`;

export const FilterTitle = styled.p`
  ${paragraphs.body2};
  font-weight: bold;
  color: ${colors.dark.base};
  margin-bottom: ${convertPxToRem(8)};
`;

export const FilterBlock = styled.div`
  > div:not(:last-child) {
    margin-bottom: ${convertPxToRem(16)};
  }

  :not(:last-child) {
    margin-bottom: ${convertPxToRem(18)};
  }
`;

export const ToggleFilterBlock = styled(FilterBlock)`
  padding-top: ${convertPxToRem(16)};

  label {
    ${BagossFont};
    font-size: ${convertPxToRem(11)};
    color: ${colors.dark.base};
    order: -1;
    margin-right: ${convertPxToRem(16)};
    padding-top: ${convertPxToRem(4)};
  }
`;

export const Content = styled.div`
  padding: ${convertPxToRem(24)} 0 0;
`;

export const Title = styled.div`
  padding-bottom: ${convertPxToRem(24)};

  h5 {
    margin: 0;
  }
`;

export const NavTop = styled.div`
  height: ${convertPxToRem(68)};
  display: flex;
  align-items: center;
  padding: 0 ${convertPxToRem(16)};
  justify-content: space-between;
  box-shadow: ${shadows.level3};

  ${LogoContainer} {
    color: ${colors.dark.base};
  }
`;

export const NavBottom = styled.div`
  position: absolute;
  background: white;
  bottom: 0;
  padding: ${convertPxToRem(16)};
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  z-index: 1;
`;

export const Subtitle = styled.span`
  color: ${colors.dark.base};
`;

export const CrossWrapper = styled.button`
  padding: 0;
  border: 0;
  background: unset;
  width: ${convertPxToRem(32)};
  height: ${convertPxToRem(32)};
  color: ${colors.dark.base};

  :active,
  :focus {
    outline: none;
  }

  svg {
    width: 100%;
    height: 100%;
  }
`;
