import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ALL_PROPERTIES, DEFAULT_PROPERTIES } from '@/constants/constants';
import { Checkbox } from '@proprioo/salatim';

import { ListingTypesProps } from './interfaces';
import { CheckboxLayout, Layout } from './ListingTypes.styles';

export const ListingTypes: FC<ListingTypesProps> = ({
  orientation,
  values = DEFAULT_PROPERTIES,
  updateFilters
}) => {
  const { t } = useTranslation();
  const [listingState, setTypes] = useState<string[]>(values);

  const onChange = (label: string, isChecked: boolean) => {
    if (values) {
      const listingTypesValues: string[] = isChecked
        ? [...listingState, label]
        : listingState.filter((v: string) => v !== label);
      setTypes(listingTypesValues);

      if (JSON.stringify(values) !== JSON.stringify(listingTypesValues)) {
        updateFilters({
          typeBien: listingTypesValues
        });
      }
    }
  };

  return (
    <Layout orientation={orientation}>
      {ALL_PROPERTIES.map((label: string, key: number) => (
        <CheckboxLayout orientation={orientation} key={`listing-type-${key}`}>
          <Checkbox
            checked={(listingState && listingState.includes(label)) || false}
            label={t(label)}
            disabled={false}
            name={label}
            id={`${label}-${key}`}
            onChange={(isChecked: boolean) => onChange(label, isChecked)}
          />
        </CheckboxLayout>
      ))}
    </Layout>
  );
};

export default ListingTypes;
