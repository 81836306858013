import React, { FC, ReactNode } from 'react';

import CustomLink from '@/components/common/customLink/CustomLink';
import { URLS } from '@/constants/global';
import { generateLinkHref } from '@/utils/filters';

import { ListItem, Number, Page } from '../Pagination.styles';

export type PageNumberProps = {
  current: number;
  page: number;
  query: string;
  icon?: ReactNode;
};

const PageNumber: FC<PageNumberProps> = ({ query, current, page, icon }) => (
  <ListItem data-test="layout-pagination">
    <CustomLink
      link={{
        ...generateLinkHref(URLS.SEARCH, `${query}&page=${page}`),
        label: `${URLS.SEARCH.label}-page-${page}`,
        isInternal: true
      }}
    >
      <Page data-test={`page-number-${page}`} isCurrent={page === current}>
        {icon ? icon : <Number>{page}</Number>}
      </Page>
    </CustomLink>
  </ListItem>
);

export default PageNumber;
