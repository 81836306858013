import isMobile from 'ismobilejs';
import { GetServerSideProps, NextPage } from 'next';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { getServerState, InstantSearchServerState } from 'react-instantsearch';

import PageMeta from '@/components/app/pageMeta/PageMeta';
import { getLocationsByIds } from '@/components/common/locationRefinement/fetch';
import { INITIAL_FILTERS_STATE } from '@/components/search/filtersProvider/FiltersProvider';
import {
  AlgoliaSearchState,
  LocationState
} from '@/components/search/search/interfaces';
import Search from '@/components/search/search/Search';
import {
  generateSearchState,
  getLocationIdsFromQuery,
  getLocationNames,
  getLocationState,
  getStateFromFilters
} from '@/components/search/search/utils';
import { FRONT_URL, LOCAL_API } from '@/constants/global';
import { useGenerateTitles } from '@/hooks/useGenerateTitles/useGenerateTitles';
import {
  cleanQueryState,
  generateObjectToQuery,
  readQueryString
} from '@/utils/algolia';
import { DefaultLayout } from '@/utils/layout';
import { toastError } from '@/utils/notification';
import { DEFAULT_HEADER } from '@/utils/page';
import { ArbitraryObject } from '@/utils/test-utils';

export type CustomSearchProps = {
  hitsPerPage: number;
  initialLocationState: LocationState[];
  searchState: ArbitraryObject;
  serverState?: InstantSearchServerState;
  serverUrl: string;
};

const CustomSearch: NextPage<CustomSearchProps> = ({
  hitsPerPage,
  initialLocationState,
  searchState,
  serverState,
  serverUrl
}) => {
  const { t } = useTranslation();
  const { query } = useRouter();

  const [{ lastQuery, locationState, search }, setSearch] = useState<{
    lastQuery: string;
    locationState: LocationState[];
    search: ArbitraryObject;
  }>({
    lastQuery: generateObjectToQuery(query),
    locationState: initialLocationState,
    search: searchState
  });

  const stateFromFilter = getStateFromFilters(search);
  const locationNames = getLocationNames(locationState);
  const { headerTitle, pageTitle } = useGenerateTitles(
    stateFromFilter,
    locationNames
  );

  useEffect(() => {
    const currentQuery = generateObjectToQuery(query);

    if (currentQuery !== lastQuery) {
      const state = generateSearchState(
        cleanQueryState(readQueryString(currentQuery)),
        hitsPerPage
      );

      (async () => {
        try {
          const locationIds = getLocationIdsFromQuery(query);
          const locationsFromQueryIds = await getLocationsByIds(
            locationIds,
            FRONT_URL
          );
          const locationState = getLocationState(locationsFromQueryIds);

          setSearch({ lastQuery: currentQuery, locationState, search: state });
        } catch (error) {
          toastError('geoSearchError');
        }
      })();
    }
  }, [query]);

  return (
    <PageMeta description={t('descriptionSearch')} title={pageTitle}>
      <DefaultLayout>
        <Search
          headerData={{
            ...DEFAULT_HEADER,
            breadCrumb: t('ads'),
            title: headerTitle
          }}
          hitsPerPage={hitsPerPage}
          initialLocationState={locationState}
          searchState={search}
          serverState={serverState}
          serverUrl={serverUrl}
        />
      </DefaultLayout>
    </PageMeta>
  );
};

export const getServerSideProps: GetServerSideProps<
  CustomSearchProps
> = async ({ req, query }) => {
  const cloudfrontMobile = `${req?.headers['cloudfront-is-mobile-viewer']}`;
  const userAgent =
    req?.headers['user-agent'] ||
    (process.browser
      ? window.navigator && window.navigator.userAgent
      : undefined);

  const hitsPerPage =
    cloudfrontMobile === 'true' || (userAgent && isMobile(userAgent).phone)
      ? 10
      : 18;

  const stringifiedQuery = generateObjectToQuery(query);

  const queryState: AlgoliaSearchState = stringifiedQuery
    ? readQueryString(stringifiedQuery)
    : INITIAL_FILTERS_STATE;

  const filteredQueryState = cleanQueryState(queryState);
  const searchState = generateSearchState(filteredQueryState, hitsPerPage);

  if (searchState.page > 100) {
    return { notFound: true };
  }

  const locationIds = getLocationIdsFromQuery(query);
  const locationByIds = await getLocationsByIds(locationIds, LOCAL_API);
  const initialLocationState = getLocationState(locationByIds);

  const initialLocationStateWithoutCoordinates = initialLocationState.map(
    location => ({
      ...location,
      coordinates: []
    })
  );

  const protocol = req.headers.referer?.split('://')[0] || 'https';
  const serverUrl = `${protocol}://${req.headers.host}${req.url}`;

  const serverState = await getServerState(
    <Search
      headerData={DEFAULT_HEADER}
      hitsPerPage={hitsPerPage}
      initialLocationState={initialLocationStateWithoutCoordinates}
      searchState={searchState}
      serverUrl={serverUrl}
    />,
    { renderToString }
  );

  return {
    props: {
      hitsPerPage,
      initialLocationState: initialLocationStateWithoutCoordinates,
      searchState,
      serverState,
      serverUrl
    }
  };
};

export default CustomSearch;
