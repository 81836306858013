import styled, { css } from 'styled-components';

import { colors, convertPxToRem, paragraphs, shadows } from '@proprioo/salatim';

import { fixedBarTop } from '../FiltersBar.styles';

export const Layout = styled.aside<{
  inNavigation?: boolean;
  isSticky?: boolean;
}>`
  display: flex;
  background: white;
  border-radius: ${convertPxToRem(33)};
  box-shadow: ${shadows.level2};
  overflow: hidden;

  ${({ inNavigation }) =>
    inNavigation &&
    css`
      position: fixed;
      top: ${fixedBarTop};
      left: 0;
      transition: top 0.2s;
      width: 100%;
      background: white;
      box-shadow: ${shadows.level2};
      border-radius: 0;
    `}

  ${({ isSticky }) =>
    isSticky &&
    css`
      top: 0;
    `};
`;

export const SearchButton = styled.div<{ isOpen: boolean }>`
  flex: 1;
  height: ${convertPxToRem(66)};
  display: flex;
  align-items: center;
  padding: 0 ${convertPxToRem(12)} 0 ${convertPxToRem(24)};
  ${paragraphs.body2};
  color: ${colors.grey.base};
  justify-content: space-between;
  font-weight: 600;
  font-size: ${convertPxToRem(13)};

  svg {
    width: ${convertPxToRem(32)};
    height: ${convertPxToRem(32)};
    transition: transform 0.2s;
    transform: rotate(90deg);

    ${({ isOpen }) =>
      isOpen &&
      css`
        transform: rotate(-90deg);
      `}
  }
`;
