import * as yup from 'yup';

export const ProjectSchema = yup.object({
  purchaseStage: yup
    .object({
      label: yup.string().required(),
      value: yup.string().required()
    })
    .nullable(),
  isSeller: yup
    .object({
      label: yup.string().required(),
      value: yup.string().required()
    })
    .nullable(),
  creditStage: yup
    .object({
      label: yup.string().required(),
      value: yup.string().required()
    })
    .nullable(),
  enabled: yup.boolean()
});
