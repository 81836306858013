import styled, { css } from 'styled-components';

import {
  BagossFont,
  breakpoints,
  colors,
  convertPxToRem,
  fonts,
  radius
} from '@proprioo/salatim';

type CurrentProps = {
  isCurrent: boolean;
};

export const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;

  @media (min-width: ${breakpoints.base}px) {
    justify-content: flex-start;
  }
`;

export const ListItem = styled.li`
  & + & {
    margin-left: ${convertPxToRem(2)};
  }
`;

const basePage = css`
  ${BagossFont};
  width: ${convertPxToRem(40)};
  height: ${convertPxToRem(40)};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.dark.base};
  text-decoration: unset;
  border-radius: ${radius.normal};
  font-size: ${fonts.base};
  transition: 0.2s all;
  line-height: 1;
  font-weight: 500;

  svg {
    width: ${convertPxToRem(32)};
    height: ${convertPxToRem(32)};
    color: ${colors.terracota.base};
  }
`;

export const Page = styled.div<CurrentProps>`
  ${basePage};
  cursor: pointer;

  &:hover,
  &:active {
    background-color: ${colors.terracota.base};
    color: white;

    svg {
      color: currentColor;
    }
  }

  &:active,
  &:focus {
    outline: unset;
  }

  ${({ isCurrent }) =>
    isCurrent &&
    css`
      background: ${colors.grey.base20};
      color: ${colors.dark.base};
      pointer-events: none;
    `}
`;

export const Number = styled.span`
  padding-top: ${convertPxToRem(3)};
`;

export const Separator = styled(ListItem)`
  ${basePage}
`;
