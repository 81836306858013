import qs from 'qs';
import { useTranslation } from 'react-i18next';

import { getTruthyCriteria } from '@/components/search/filters/Filters.utils';
import { DEFAULT_OTHER } from '@/components/search/filtersProvider/FiltersProvider';
import {
  LocationState,
  RangeFilterValue,
  TypedAlgoliaSearchState,
  TypedCriteriaUrl
} from '@/components/search/search/interfaces';
import {
  getLocationIds,
  getLocationNameWithoutDistrict
} from '@/components/search/search/utils';
import { readQueryString } from '@/utils/algolia';
import {
  Dictionary,
  getKeyFromPath,
  Language,
  normalizePhoneNumber,
  nullOrNumber,
  OptionProps,
  PropertyEnum,
  reduceDictionary,
  replaceSingleQuotionMark,
  trimName,
  UserProps
} from '@proprioo/hokkaido';

import { BuyerAlertFormSchema } from './BuyerAlertForm.schema';
import {
  CriteriaRangeTypes,
  CriteriaState,
  SelectedProperties
} from './criteriaForm/CriteriaForm.types';
import {
  AlertFormState,
  AlertObjectResponse,
  AlertPayload,
  CreatedFrom,
  CriteriaTypesCheck,
  DefaultCriteriaState,
  ProjectPayload,
  ProjectState,
  QualificationProps,
  RangeType,
  UserPayload
} from './interfaces';

export const IS_SELLER = 'isSeller';
export const IS_NOT_SELLER = 'isNotSeller';

export const generateEmptyRange = (): RangeFilterValue => ({
  min: null,
  max: null
});

export const generateEmptyUser = (): UserProps => ({
  customerProfile: null,
  email: '',
  firstName: '',
  language: Language.FRENCH,
  lastName: '',
  phone: null
});

export const generateEmptyProject = (): ProjectState => ({
  agentOwner: null,
  createdFrom: CreatedFrom.ALERT,
  creditStage: null,
  enabled: true,
  internalComment: null,
  isSeller: null,
  purchaseStage: null,
  source: 'websiteVisitor'
});

export const defaultPropertyTypes = {
  house: true,
  flat: true
};

export const generateDefaultCriteria = (
  defaultCriteria: DefaultCriteriaState = {}
): CriteriaState => ({
  locationState: defaultCriteria.locationState || [],
  propertyTypes: defaultCriteria.propertyTypes || defaultPropertyTypes,
  nbBedrooms: { ...generateEmptyRange(), ...defaultCriteria.nbBedrooms },
  rooms: { ...generateEmptyRange(), ...defaultCriteria.rooms },
  surface: { ...generateEmptyRange(), ...defaultCriteria.surface },
  budget: { ...generateEmptyRange(), ...defaultCriteria.budget },
  land: { ...generateEmptyRange(), ...defaultCriteria.land },
  other: { ...DEFAULT_OTHER, ...defaultCriteria.other },
  isMap: defaultCriteria.isMap || false,
  enabled: true
});

export const generateEmptyAlertOpportunity = (): AlertFormState => ({
  criteria: generateDefaultCriteria(),
  project: generateEmptyProject(),
  user: generateEmptyUser(),
  promotionalConsent: false
});

export const getSelectOptions = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation();
  return {
    isSellerOptions: [
      {
        value: IS_SELLER,
        label: t('yes')
      },
      {
        value: IS_NOT_SELLER,
        label: t('no')
      }
    ],
    creditStageOptions: [
      {
        value: 'noNeed',
        label: t('noNeed')
      },
      {
        value: 'noButINeedOne',
        label: t('noButNeed')
      },
      {
        value: 'inProgress',
        label: t('yesButWait')
      },
      {
        value: 'accepted',
        label: t('yesAndHave')
      }
    ],
    creditStageVisitOptions: [
      {
        value: 'true',
        label: t('yes')
      },
      {
        value: 'false',
        label: t('no')
      }
    ],
    purchaseStageOptions: [
      {
        value: 'kickOff',
        label: t('informationState')
      },
      {
        value: 'visiting',
        label: t('visitsState')
      },
      {
        value: 'alreadyMadeOffer',
        label: t('offerState')
      }
    ]
  };
};

/* Give similar field (ex: 'criteria.rooms.min' return ['criteria.rooms.min', 'criteria.rooms.max']) */
export const getMinMaxFields = (field: string) => {
  if (field === CriteriaTypesCheck.HOUSE || field === CriteriaTypesCheck.FLAT) {
    return Object.values(CriteriaTypesCheck);
  } else if (field.includes(RangeType.MIN) || field.includes(RangeType.MAX)) {
    const path = field.substring(0, field.length - 4);
    return [`${path}.${RangeType.MIN}`, `${path}.${RangeType.MAX}`];
  }
  return [field];
};

export const getCriteriaOnRangeChange = (
  criteria: CriteriaState,
  criteriaRangeKey: CriteriaRangeTypes,
  newValue: nullOrNumber,
  rangeType: RangeType
): CriteriaState => {
  const range = criteria[criteriaRangeKey] || generateEmptyRange();
  range[rangeType] = newValue;
  criteria[criteriaRangeKey] = range;
  return criteria;
};

export function getErrorsFromFields<T>(
  newProperties: T,
  fields: string[]
): Dictionary {
  return reduceDictionary(
    fields.map(field => validateField<T>(newProperties, field))
  );
}

export function getErrors<T>(
  isSubmit: boolean,
  field: string[],
  newProperties: T
) {
  return isSubmit ? getErrorsFromFields<T>(newProperties, field) : {};
}

export function validateField<T>(
  newProperties: T,
  fieldKey: string
): Dictionary {
  const key = getKeyFromPath(fieldKey);
  try {
    BuyerAlertFormSchema
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .validateSyncAt(fieldKey, newProperties as any);
    return {
      [key]: ''
    };
  } catch ({ type }) {
    return {
      [key]: `${type}`
    };
  }
}

export const getPropertyTypes = (propertyTypes: SelectedProperties) =>
  Object.keys(propertyTypes).filter(
    (property: string) => propertyTypes[property as 'flat' | 'house']
  );

export const normalizedCriteriaPayload = (
  alert: AlertObjectResponse
): CriteriaState => {
  const { zones, enabled, isMap, propertyTypes, ...rest } = alert;

  const locationState: LocationState[] =
    zones.map(({ id, name, geom, coordinates, type }) => {
      const nameWithoutDistrict = getLocationNameWithoutDistrict(name);
      return {
        id,
        name: nameWithoutDistrict,
        geom,
        coordinates,
        type
      };
    }) || [];
  const types = convertPropertyTypes(propertyTypes);

  const nbBedrooms: RangeFilterValue = {
    min: rest.minBedrooms,
    max: rest.maxBedrooms
  };
  const rooms: RangeFilterValue = {
    min: rest.minRooms,
    max: rest.maxRooms
  };
  const budget: RangeFilterValue = {
    min: rest.minPrice,
    max: rest.maxPrice
  };
  const surface: RangeFilterValue = {
    min: rest.minSurface,
    max: rest.maxSurface
  };
  const land: RangeFilterValue = {
    min: rest.minLandingArea,
    max: rest.maxLandingArea
  };

  const hasPool = rest.hasPool || false;
  const hasOutdoorSpace = rest.hasOutdoorSpace || false;
  const hasParking = rest.hasParking || false;
  const hasCellarOrAnnex = rest.hasCellarOrAnnex || false;
  const hasLift = rest.hasLift || false;
  const groundFloor = rest.groundFloor || false;
  const withoutRenovationWork = rest.withoutRenovationWork || false;

  return {
    locationState,
    enabled,
    isMap,
    propertyTypes: types,
    nbBedrooms,
    rooms,
    budget,
    surface,
    land,
    other: {
      hasPool,
      hasOutdoorSpace,
      hasParking,
      hasCellarOrAnnex,
      hasLift,
      groundFloor,
      withoutRenovationWork
    }
  };
};

export const getOption = (options: OptionProps[], value?: string) =>
  options.filter(option => option.value === value);

export const normalizedProjectPayload = (
  project: ProjectPayload
): ProjectState => {
  const { enabled, createdFrom, source, agentOwner, internalComment } = project;

  const { isSellerOptions, creditStageOptions, purchaseStageOptions } =
    getSelectOptions();

  const isSeller = normalizeSellerPayload(project.isSeller);

  const [sellerStage] = getOption(isSellerOptions, isSeller);
  const [creditStage] = getOption(creditStageOptions, project.loanStage);
  const [purchaseStage] = getOption(
    purchaseStageOptions,
    project.purchaseStage
  );

  return {
    purchaseStage: purchaseStage || null,
    isSeller: sellerStage || null,
    creditStage: creditStage || null,
    agentOwner: agentOwner || null,
    internalComment: internalComment || null,
    createdFrom,
    enabled,
    source
  };
};

export const normalizedUserPayload = (user: UserPayload): UserProps => {
  const { email, firstname, language, lastname, phoneNumber } = user;

  return {
    customerProfile: null,
    email: trimName(email),
    firstName: replaceSingleQuotionMark(trimName(firstname)),
    language,
    lastName: replaceSingleQuotionMark(trimName(lastname)),
    phone: phoneNumber ? normalizePhoneNumber(phoneNumber) : ''
  };
};

export const formatPayloadToAlert = (payload: QualificationProps) => {
  const {
    alertID,
    projectID,
    userID,
    createdAt,
    lastModified,
    alert,
    project,
    user
  } = payload;

  return {
    ...generateEmptyAlertOpportunity(),
    alertID,
    createdAt,
    lastModified,
    projectID,
    userID,
    criteria: normalizedCriteriaPayload(alert),
    project: normalizedProjectPayload(project),
    user: normalizedUserPayload(user),
    promotionalConsent: user.promotionalConsent
  };
};

export const formatPropertyTypesToPropertyListing = (
  propertyTypes: string[]
) => {
  const formatedPropertyTypes = [];
  if (propertyTypes.includes(PropertyEnum.FLAT)) {
    formatedPropertyTypes.push(PropertyEnum.FLAT);
  }
  if (propertyTypes.includes(PropertyEnum.HOUSE)) {
    formatedPropertyTypes.push(PropertyEnum.HOUSE);
  }
  return formatedPropertyTypes;
};

export const normalizeSellerPayload = (seller?: boolean) => {
  switch (seller) {
    case true:
      return IS_SELLER;
    case false:
      return IS_NOT_SELLER;
    default:
      return;
  }
};

export const formatSellerPayload = (isSeller: OptionProps | null) => {
  const isSellerValue = isSeller?.value;
  switch (isSellerValue) {
    case IS_SELLER:
      return true;
    case IS_NOT_SELLER:
      return false;
    default:
      return;
  }
};

export const formatAlertFormToPayload = ({
  criteria,
  project,
  user,
  promotionalConsent,
  notification = false
}: AlertFormState): AlertPayload => {
  const {
    agentOwner,
    internalComment,
    isSeller,
    creditStage,
    purchaseStage,
    createdFrom = CreatedFrom.ALERT,
    source = 'websiteVisitor',
    enabled = true
  } = project;
  const { email, firstName, language, lastName, phone: phoneNumber } = user;
  const {
    rooms,
    nbBedrooms,
    budget,
    surface,
    land = generateEmptyRange(),
    propertyTypes,
    locationState,
    other,
    enabled: enabledAlert = true,
    isMap
  } = criteria;

  const criterias = {
    ...DEFAULT_OTHER,
    ...other
  };

  const seller = formatSellerPayload(isSeller);

  return {
    alert: {
      zones: getLocationIds(locationState),
      propertyTypes: getPropertyTypes(propertyTypes),
      minRooms: rooms.min,
      maxRooms: rooms.max,
      minBedrooms: nbBedrooms.min,
      maxBedrooms: nbBedrooms.max,
      minPrice: budget.min,
      maxPrice: budget.max,
      minSurface: surface.min,
      maxSurface: surface.max,
      minLandingArea: land.min,
      maxLandingArea: land.max,
      hasPool: Boolean(JSON.parse(`${criterias.hasPool}`)),
      hasCellarOrAnnex: Boolean(JSON.parse(`${criterias.hasCellarOrAnnex}`)),
      hasLift: Boolean(JSON.parse(`${criterias.hasLift}`)),
      hasParking: Boolean(JSON.parse(`${criterias.hasParking}`)),
      hasOutdoorSpace: Boolean(JSON.parse(`${criterias.hasOutdoorSpace}`)),
      withoutRenovationWork: Boolean(
        JSON.parse(`${criterias.withoutRenovationWork}`)
      ),
      groundFloor: Boolean(JSON.parse(`${criterias.groundFloor}`)),
      enabled: enabledAlert,
      isMap: Boolean(isMap && JSON.parse(`${isMap}`))
    },
    project: {
      ...(seller !== undefined && { isSeller: seller }),
      ...(creditStage && { loanStage: creditStage.value }),
      ...(purchaseStage && {
        purchaseStage: purchaseStage.value
      }),
      enabled,
      createdFrom,
      source,
      agentOwner: agentOwner || null,
      internalComment: internalComment || null
    },
    user: {
      email: trimName(email),
      firstname: replaceSingleQuotionMark(trimName(firstName)),
      language,
      lastname: replaceSingleQuotionMark(trimName(lastName)),
      phoneNumber: phoneNumber ? normalizePhoneNumber(phoneNumber) : '',
      promotionalConsent
    },
    notification
  };
};

export const convertPropertyTypes = (typeBien: string[] = []) => {
  if (typeBien.length === 0) {
    return { flat: true, house: true };
  }

  return {
    flat: typeBien
      ? typeBien.includes(PropertyEnum.FLAT) ||
        typeBien.includes(PropertyEnum.FLAT)
      : false,
    house: typeBien
      ? typeBien.includes(PropertyEnum.HOUSE) ||
        typeBien.includes(PropertyEnum.HOUSE)
      : false
  };
};

export const convertAlgoliaFiltersToCriteriaUrl = (
  {
    nbPieces: rooms,
    nbBedrooms,
    prix: budget,
    surface,
    typeBien,
    other
  }: TypedAlgoliaSearchState,
  locationIds: string[]
): string => {
  const propertyTypes = {
    ...defaultPropertyTypes,
    ...convertPropertyTypes(typeBien)
  };

  return qs.stringify(
    {
      locationIds,
      rooms,
      nbBedrooms,
      budget,
      surface,
      propertyTypes,
      other: getTruthyCriteria(other)
    },
    {
      arrayFormat: 'comma',
      addQueryPrefix: true,
      encode: false,
      strictNullHandling: true,
      skipNulls: true
    }
  );
};

export const convertCriteriaStateToCriteriaUrl = ({
  rooms,
  nbBedrooms,
  budget,
  surface,
  propertyTypes,
  other,
  land,
  locationState,
  isMap,
  alertId
}: CriteriaState): string => {
  const locationIds = getLocationIds(locationState || []);

  return qs.stringify(
    {
      locationIds,
      isMap,
      land,
      rooms,
      nbBedrooms,
      budget,
      surface,
      propertyTypes,
      alertId,
      other: getTruthyCriteria(other)
    },
    {
      arrayFormat: 'comma',
      addQueryPrefix: true,
      encode: false,
      strictNullHandling: true,
      skipNulls: true
    }
  );
};

export const convertCriteriaUrlToCriteriaState = (
  {
    rooms,
    land,
    nbBedrooms,
    budget,
    prix,
    surface,
    propertyTypes,
    typeBien,
    other,
    isMap = false
  }: TypedCriteriaUrl,
  locationState: LocationState[]
): CriteriaState => {
  const flat = Boolean(
    (propertyTypes?.flat && Boolean(JSON.parse(`${propertyTypes.flat}`))) ||
      typeBien?.includes(PropertyEnum.FLAT)
  );
  const house = Boolean(
    (propertyTypes?.house && Boolean(JSON.parse(`${propertyTypes.house}`))) ||
      typeBien?.includes(PropertyEnum.HOUSE)
  );

  const hasProperties = Boolean(flat || house);

  const property = {
    ...defaultPropertyTypes,
    ...(hasProperties && {
      flat,
      house
    })
  };

  return generateDefaultCriteria({
    locationState,
    land,
    rooms,
    nbBedrooms,
    budget: budget || prix,
    surface,
    propertyTypes: property,
    other,
    isMap: JSON.parse(`${isMap}`)
  });
};

export const convertCriteriaStateToAlgoliaSearch = ({
  locationState,
  rooms: nbPieces,
  nbBedrooms,
  budget: prix,
  surface,
  propertyTypes,
  other
}: CriteriaState): TypedAlgoliaSearchState => {
  const typeBien = [];
  if (propertyTypes.flat) {
    typeBien.push(PropertyEnum.FLAT);
  }
  if (propertyTypes.house) {
    typeBien.push(PropertyEnum.HOUSE);
  }

  return {
    locationIds: getLocationIds(locationState),
    nbPieces,
    nbBedrooms,
    surface,
    prix,
    typeBien,
    other
  };
};

/* Warning this object is used by marketing via SendInBlue 
Please warn data if you add/update properties name */
export const generateInitialAlertOpportunityFromUrl = (
  path: string,
  locationState: LocationState[]
) => {
  const querySearchState: TypedCriteriaUrl = readQueryString(path);

  return {
    ...generateEmptyAlertOpportunity(),
    criteria: convertCriteriaUrlToCriteriaState(querySearchState, locationState)
  };
};
