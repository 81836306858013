import * as prismic from '@prismicio/client';

const API_END_POINT = 'proprioo';
const ACCESS_TOKEN =
  'MC5YbkRkclJJQUFDa0FCT21w.NApbEu-_ve-_vW3vv71h77-9KO-_vXo977-9Au-_vRTvv71zX--_ve-_ve-_ve-_vRN777-977-9OVLvv70';

const endpoint = prismic.getRepositoryEndpoint(API_END_POINT);
const clientPrismic = prismic.createClient(endpoint, {
  accessToken: ACCESS_TOKEN
});

export default clientPrismic;
