import styled, { css } from 'styled-components';

import { convertPxToRem } from '@proprioo/salatim';

import { ListingTypesProps, Orientation } from './interfaces';

export const CheckboxLayout = styled.div<
  Pick<ListingTypesProps, 'orientation'>
>`
  ${({ orientation }) =>
    orientation === Orientation.HORIZONTAL &&
    css`
      margin-right: ${convertPxToRem(32)};
    `}

  & + & {
    margin-top: ${convertPxToRem(8)};
  }
`;

export const Layout = styled.div<Pick<ListingTypesProps, 'orientation'>>`
  ${({ orientation }) =>
    orientation === Orientation.HORIZONTAL &&
    css`
      display: flex;
    `}
`;
