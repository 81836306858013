import React, { FC } from 'react';
import { useStats } from 'react-instantsearch';

import { generateQueryString, searchStateToURLObject } from '@/utils/algolia';
import { getArrayOfLength } from '@proprioo/hokkaido';

import { generateLocationParam } from '../search/utils';
import BasicPagination from './basicPagination/BasicPagination';
import EndPagination from './endPagination/EndPagination';
import { PaginationProps } from './interfaces';
import MiddlePagination from './middlePagination/MiddlePagination';
import { List } from './Pagination.styles';
import StartPagination from './startPagination/StartPagination';
import {
  isAfterEndLandmark,
  isBeforeLandmark,
  isComplexPagination
} from './utils';

export type PaginationFCProps = {
  currentRefinement: number;
  filters: string;
  hitsPerPage: number;
  locationIds: string[];
};

export const Pagination: FC<PaginationFCProps> = ({
  currentRefinement,
  filters,
  hitsPerPage,
  locationIds
}) => {
  const { nbHits } = useStats();

  const nbPages = Math.ceil(nbHits / hitsPerPage);
  const params = generateQueryString(searchStateToURLObject(filters));
  const locationParams = generateLocationParam(locationIds);
  const queryString = params.concat(locationParams);

  const paginationProps: PaginationProps = {
    nbPages,
    currentRefinement,
    query: queryString,
    arrayOfPages: getArrayOfLength(nbPages)
  };

  return (
    <List data-test="pagination">
      {isComplexPagination(nbPages) ? (
        isBeforeLandmark(currentRefinement) ? (
          <StartPagination {...paginationProps} data-test="start" />
        ) : isAfterEndLandmark(currentRefinement, nbPages) ? (
          <EndPagination {...paginationProps} data-test="end" />
        ) : (
          <MiddlePagination {...paginationProps} data-test="middle" />
        )
      ) : (
        <BasicPagination {...paginationProps} data-test="basic" />
      )}
    </List>
  );
};

export default Pagination;
