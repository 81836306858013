import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStats } from 'react-instantsearch';

import { Layout } from './NbHits.styles';

const NbHits = () => {
  const { t } = useTranslation();
  const { nbHits } = useStats();

  return (
    <Layout data-test="nbhits-value">{t('nbHits', { count: nbHits })}</Layout>
  );
};

export default NbHits;
