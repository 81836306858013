import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import ArrowRight from '@/assets/icons/arrow.svg';

import { PaginationProps } from '../interfaces';
import PageNumber from '../pageNumber/PageNumber';
import { Separator } from '../Pagination.styles';
import { atStart } from '../utils';

const StartPagination: FC<PaginationProps> = ({
  currentRefinement,
  nbPages,
  arrayOfPages,
  query
}) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      {arrayOfPages.map(
        (page: number) =>
          atStart(page) && (
            <PageNumber
              data-test="page-number"
              page={page}
              current={currentRefinement}
              query={query}
              key={`page-${page}`}
            />
          )
      )}
      <Separator>{t('points')}</Separator>
      <PageNumber
        data-test="last"
        page={nbPages}
        current={currentRefinement}
        query={query}
      />
      <PageNumber
        data-test="next"
        icon={<ArrowRight />}
        page={currentRefinement + 1}
        query={query}
        current={currentRefinement}
      />
    </Fragment>
  );
};

export default StartPagination;
