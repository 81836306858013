import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { Layout } from './Modal.styles';

type ModalProps = {
  isOpen: boolean;
};

const Modal: FC<PropsWithChildren<ModalProps>> = ({ isOpen, children }) => {
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    setOpen(isOpen);
    return () => setOpen(false);
  }, []);

  return createPortal(
    <Layout id="modal-container" isOpen={open}>
      {children}
    </Layout>,
    document.body
  );
};

export default Modal;
