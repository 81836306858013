import { useRouter } from 'next/router';
import qs from 'qs';
import React, { FC, Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Arrow from '@/assets/icons/arrow.svg';
import Close from '@/assets/icons/close_circled.svg';
import Casavo from '@/assets/svg/casavo.svg';
import { Orientation } from '@/components/alert/listingTypes/interfaces';
import ListingTypes from '@/components/alert/listingTypes/ListingTypes';
import { LogoContainer } from '@/components/app/navbar/Navbar.styles';
import CustomLink from '@/components/common/customLink/CustomLink';
import LocationRefinement from '@/components/common/locationRefinement/LocationRefinement';
import { URLS } from '@/constants/global';
import { getRangeState } from '@/utils/filters';
import { PageColors } from '@/utils/page';
import { TypeColor } from '@/utils/prismic';
import { ListingStatus } from '@proprioo/hokkaido';
import { Button, Container, Fieldset, H5, Toggle } from '@proprioo/salatim';

import OtherCriterias from '../criterias/OtherCriterias';
import CustomRange from '../customRange/CustomRange';
import { FilterTypes } from '../filterDropdown/interfaces';
import { FiltersContextProps } from '../filtersProvider/FiltersProvider';
import { OtherCriteriasProps } from '../search/interfaces';
import {
  Content,
  CrossWrapper,
  FilterBlock,
  FilterTitle,
  GlobalContainer,
  Layout,
  NavBottom,
  NavTop,
  Title,
  ToggleFilterBlock
} from './FiltersModal.styles';

export type FiltersModalProps = FiltersContextProps & {
  isOpen: boolean;
  toggleIsOpen(): void;
};

const FiltersModal: FC<FiltersModalProps> = ({
  isOpen,
  locationState,
  searchState,
  applyFilters,
  toggleIsOpen,
  toggleSold,
  updateFilters
}) => {
  const router = useRouter();
  const { t } = useTranslation();

  const updateOther = (other: OtherCriteriasProps) => {
    updateFilters({ ...searchState, other });
  };

  useEffect(() => {
    if (isOpen) {
      const params = qs.stringify(
        { modal: 1, ...router.query },
        { addQueryPrefix: true }
      );
      router.replace(`${router.route}${params}`, undefined, { shallow: true });
    }

    router.beforePopState(() => {
      if (isOpen) {
        toggleIsOpen();
        return false;
      }

      return true;
    });

    return () => router.beforePopState(() => true);
  }, [isOpen]);

  return (
    <Layout data-test="filters-modal">
      <GlobalContainer>
        <NavTop>
          <LogoContainer isOpen={false}>
            <CustomLink link={URLS.HOMEPAGE} id={`navbar-link-homepage`}>
              <Casavo />
            </CustomLink>
          </LogoContainer>
          <CrossWrapper onClick={toggleIsOpen}>
            <Close />
          </CrossWrapper>
        </NavTop>
        <Container>
          <Content>
            <Fragment>
              <Title data-test="search-title">
                <H5
                  title={t('yourSearchTitle')}
                  color={PageColors.get(TypeColor.GREEN)}
                />
              </Title>
              <FilterBlock>
                <Fieldset label={t('fixedSearchInputPlaceholder')}>
                  <LocationRefinement
                    applyFilters={applyFilters}
                    locationState={locationState}
                    updateFilters={updateFilters}
                  />
                </Fieldset>
              </FilterBlock>
              <FilterBlock>
                <Fieldset label={t('typeBien')}>
                  <ListingTypes
                    orientation={Orientation.VERTICAL}
                    updateFilters={updateFilters}
                    values={searchState[FilterTypes.TYPE] as string[]}
                  />
                </Fieldset>
              </FilterBlock>
              <FilterBlock>
                <FilterTitle>{t('numberOfRooms')}</FilterTitle>
                <CustomRange
                  attribute={FilterTypes.ROOMS}
                  data-test="wording-rooms"
                  maxLabel={'maxLabel'}
                  minLabel={'minLabel'}
                  state={getRangeState(searchState, FilterTypes.ROOMS)}
                  updateFilters={updateFilters}
                />
              </FilterBlock>
              <FilterBlock>
                <FilterTitle>{t('numberOfBedRooms')}</FilterTitle>
                <CustomRange
                  attribute={FilterTypes.BEDROOMS}
                  data-test="wording-bedrooms"
                  maxLabel={'maxLabel'}
                  minLabel={'minLabel'}
                  state={getRangeState(searchState, FilterTypes.BEDROOMS)}
                  updateFilters={updateFilters}
                />
              </FilterBlock>
              <FilterBlock>
                <FilterTitle>{t('prix')}</FilterTitle>
                <CustomRange
                  attribute={FilterTypes.PRICE}
                  data-test="wording-budget"
                  maxLabel={'maxLabel'}
                  minLabel={'minLabel'}
                  state={getRangeState(searchState, FilterTypes.PRICE)}
                  suffix={'euro'}
                  updateFilters={updateFilters}
                />
              </FilterBlock>
              <FilterBlock>
                <FilterTitle>{t('surface')}</FilterTitle>
                <CustomRange
                  attribute={FilterTypes.SURFACE}
                  data-test="wording-surface"
                  maxLabel={'maxLabel'}
                  minLabel={'minLabel'}
                  state={getRangeState(searchState, FilterTypes.SURFACE)}
                  suffix={'squareFeet'}
                  updateFilters={updateFilters}
                />
              </FilterBlock>
              <FilterBlock>
                <FilterTitle>{t('otherCriteriaLabel')}</FilterTitle>
                <OtherCriterias
                  updateValue={updateOther}
                  other={searchState.other}
                />
              </FilterBlock>
              <ToggleFilterBlock>
                <Toggle
                  label={t('seeSold')}
                  name="sold-listing-toggle"
                  id="sold-listing-toggle"
                  checked={searchState.status === ListingStatus.SOLD}
                  onChange={checked => toggleSold(checked)}
                />
              </ToggleFilterBlock>
            </Fragment>
          </Content>
        </Container>
      </GlobalContainer>
      <NavBottom>
        <Button
          data-test="apply-filters"
          label={t('apply')}
          onClick={() => {
            applyFilters();
            toggleIsOpen();
          }}
          icon={<Arrow />}
        />
      </NavBottom>
    </Layout>
  );
};

export default FiltersModal;
