import { AlgoliaSearchState } from '@/components/search/search/interfaces';

export enum Orientation {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical'
}

export type ListingTypesProps = {
  orientation?: Orientation;
  values: string[];
  updateFilters(state: AlgoliaSearchState): void;
};
