import styled from 'styled-components';

import {
  BagossFont,
  breakpoints,
  colors,
  convertPxToRem,
  fonts
} from '@proprioo/salatim';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${convertPxToRem(16)};
  margin: 0 auto;
  max-width: ${convertPxToRem(500)};

  @media (min-width: ${breakpoints.small}px) {
    grid-template-columns: repeat(2, 1fr);
    max-width: unset;
  }

  @media (min-width: ${breakpoints.base}px) {
    margin-top: ${convertPxToRem(24)};
    grid-gap: ${convertPxToRem(32)};
  }

  @media (min-width: ${breakpoints.normal}px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const SearchTop = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: ${convertPxToRem(16)};
  align-items: center;

  @media (min-width: ${breakpoints.base}px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const ToggleWrapper = styled.div`
  display: none;

  @media (min-width: ${breakpoints.base}px) {
    display: block;
  }

  label {
    ${BagossFont};
    font-size: ${fonts.base};
    color: ${colors.dark.base};
    order: -1;
    margin-right: ${convertPxToRem(16)};
    padding-top: ${convertPxToRem(4)};
  }
`;

export const NbHitsContainer = styled.div`
  text-align: center;
`;
