import React, { FC, Fragment, useMemo } from 'react';
import { useHits } from 'react-instantsearch';

import { convertAlgoliaFiltersToCriteriaUrl } from '@/components/alert/buyerAlertForm/utils';
import { ListingCardHitProps } from '@/components/common/listingCard/interfaces';
import ListingCard from '@/components/common/listingCard/ListingCard';
import { UrlProps, URLS } from '@/constants/global';
import { generateLinkHref } from '@/utils/filters';

import NoResults from '../noResults/NoResults';
import { AlgoliaSearchState, LocationState } from '../search/interfaces';
import { getLocationIds } from '../search/utils';
import { Wrapper } from './CustomHits.styles';
import { ListingHit } from './interfaces';
import { convertToListingCardProps, haveHits } from './utils';

type CustomHitsProps = {
  searchState: AlgoliaSearchState;
  locationState: LocationState[];
};

export const CustomHits: FC<CustomHitsProps> = ({
  locationState,
  searchState
}) => {
  const { hits } = useHits<ListingHit>();

  const url = convertAlgoliaFiltersToCriteriaUrl(
    searchState,
    getLocationIds(locationState || [])
  );

  const alertLink: UrlProps = {
    ...URLS.ALERT,
    ...generateLinkHref(URLS.ALERT, url)
  };

  const hitsToListing: ListingCardHitProps[] = useMemo(
    () => hits.map(convertToListingCardProps),
    [hits]
  );

  return (
    <Fragment>
      {haveHits(hits) ? (
        <Wrapper data-test="custom-hit-wrapper">
          {hitsToListing.map(hit => (
            <ListingCard {...hit} key={hit.id} data-test="hit-listing-card" />
          ))}
        </Wrapper>
      ) : (
        <NoResults alertLink={alertLink} />
      )}
    </Fragment>
  );
};

export default CustomHits;
