import { CardWrapper } from '@/components/common/listingCardDetail/ListingCardDetail.styles';
import styled from 'styled-components';

import { breakpoints, convertPxToRem } from '@proprioo/salatim';
import { SliceContent } from '../Slices.styles';

export const SoldListingContent = styled(SliceContent)`
  ${CardWrapper} {
    width: ${convertPxToRem(286)};

    @media (min-width: ${breakpoints.base}px) {
      width: auto;
    }
  }
`;
