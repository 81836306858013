import { ActionsUnion, createActionPayload } from '@/utils/actions';
import { ArbitraryObject } from '@/utils/test-utils';

type ReducerTemporarySearch = {
  temporaryStateSearch: ArbitraryObject;
  temporaryLocationState: string[];
};

const UPDATE_TEMPORARY = 'updateTemporary';

export const SearchActions = {
  updateTemporary: createActionPayload<
    typeof UPDATE_TEMPORARY,
    ReducerTemporarySearch
  >(UPDATE_TEMPORARY)
};

type AcceptedActions = ActionsUnion<typeof SearchActions>;

export const reducerSearch = (
  state: ReducerTemporarySearch,
  { type, payload }: AcceptedActions
) => {
  switch (type) {
    case UPDATE_TEMPORARY:
      return { ...state, ...payload };
    default:
      throw new Error();
  }
};
