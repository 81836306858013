import 'rheostat/initialize';

import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RheoStat, { PublicState } from 'rheostat';

import {
  AlgoliaSearchState,
  RangeFilterValue
} from '@/components/search/search/interfaces';
import {
  algorithm,
  nullOrNumber,
  roundNumber,
  setMinSlideValue
} from '@proprioo/hokkaido';
import { NumberInput } from '@proprioo/salatim';

import { FilterTypes } from '../filterDropdown/interfaces';
import { InputWrapper, Layout, RheoStateWrapper } from './CustomRange.styles';

type RangeState = {
  stateMin: nullOrNumber;
  stateMax: nullOrNumber;
};

export type CustomRangeProps = {
  attribute: FilterTypes;
  minLabel: string;
  maxLabel: string;
  state: RangeFilterValue;
  maxSliderValue?: number;
  suffix?: string;
  updateFilters(state: AlgoliaSearchState): void;
};

const CustomRange: FC<CustomRangeProps> = ({
  attribute,
  minLabel,
  updateFilters,
  maxLabel,
  maxSliderValue,
  state: { min, max },
  suffix
}) => {
  const { t } = useTranslation();

  const [{ stateMin, stateMax }, setRangeState] = useState<RangeState>({
    stateMin: min || null,
    stateMax: max || null
  });

  const onChangeMin = (number: nullOrNumber) => {
    const minNumber = number !== null ? Number(number) : null;

    setRangeState({ stateMin: minNumber, stateMax });

    if (minNumber !== min) {
      updateFilters({
        [attribute]: { min: minNumber, max: stateMax }
      });
    }
  };

  const onChangeMax = (number: nullOrNumber) => {
    const maxNumber = number !== null ? Number(number) : null;

    setRangeState({
      stateMin,
      stateMax: maxNumber
    });

    if (maxNumber !== max) {
      updateFilters({
        [attribute]: { min: stateMin, max: maxNumber }
      });
    }
  };

  const onSlideChange = (range: PublicState) => {
    const {
      values: [changedMin, changedMax]
    } = range;
    if (changedMin !== min || changedMax !== max) {
      updateFilters({
        [attribute]: {
          max: roundNumber(changedMax),
          min: roundNumber(changedMin)
        }
      });
    }
  };

  const onValuesUpdated = (range: PublicState) => {
    const {
      values: [changedMin, changedMax]
    } = range;
    setRangeState({ stateMin: changedMin, stateMax: changedMax });
  };

  return (
    <Layout>
      <InputWrapper>
        <NumberInput
          dataTest={`${attribute}-min-input`}
          label={t(minLabel)}
          disabled={false}
          error={false}
          value={stateMin}
          onChange={onChangeMin}
          suffix={suffix && t(suffix)}
          id={`${attribute}-min`}
        />
        <NumberInput
          dataTest={`${attribute}-max-input`}
          label={t(maxLabel)}
          disabled={false}
          error={false}
          value={stateMax}
          onChange={onChangeMax}
          suffix={suffix && t(suffix)}
          id={`${attribute}-max`}
        />
      </InputWrapper>
      {Boolean(maxSliderValue) && maxSliderValue && (
        <RheoStateWrapper>
          <RheoStat
            algorithm={algorithm}
            min={0}
            max={maxSliderValue}
            values={[
              setMinSlideValue(stateMin, maxSliderValue),
              stateMax || maxSliderValue
            ]}
            onChange={onSlideChange}
            onValuesUpdated={onValuesUpdated}
          />
        </RheoStateWrapper>
      )}
    </Layout>
  );
};

export default CustomRange;
